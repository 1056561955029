import styled from 'styled-components';
import Button from '../../Components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const TasksStepContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: calc(100vh - 115px);
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
`;

export const LeftSide = styled.div`
  max-width: 360px;
  width: 100%;
  height: 100%;
  border-right: 1px solid #e5e5e5;
  padding: 20px 20px;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  height: 100%;
  padding-left: 20px;
  padding-top: 20px;
`;

export const BottomBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 60px 10px 20px;
  border-top: 1px solid #e5e5e5;
  background-color: #ffffff;
  z-index: 3;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const DescriptionIcon = styled.div`
  margin-right: 10px;
`;

export const DescriptionText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 10px;
`;

export const DescriptionCreditsText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
`;

export const BottomButton = styled(Button)`
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ProjectSelect = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 10px;
  cursor: pointer;
`;

export const ProjectSelectIcon = styled.div`
  margin-left: 5px;
`;

export const ProjectIcon = styled.div<{ src: string }>`
  width: 28px;
  height: 26px;
  margin-right: 10px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const HeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const CreditsInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
`;

export const CreditsLeft = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
`;

export const GetMoreCredits = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #d9d9d9;
  margin-bottom: 8px;
`;

export const ProgressBarLine = styled.div<{ scaleRatio: number }>`
  height: 4px;
  width: 100%;
  background-color: #3b2651;
  transform-origin: 0 center;
  transform: scaleX(${({ scaleRatio }) => (scaleRatio > 1 ? 1 : scaleRatio)});
  transition: transform 0.2s ease;
`;

export const BottomBarRightSide = styled.div`
  display: flex;
  align-items: center;
`;
