import React, { FC, useEffect, useMemo, useState } from 'react';

import { useOrganization } from '../../Hooks/useOrganization';
import { CATEGORIES, PROMPT_ICONS, PropmptTemplate } from '../../Consts/promptTemplates';
import PromptDefaultIcon from '../../assets/projects/default-icons/Red-Thread.svg';

import {
  ContentWrapper,
  Sidebar,
  SidebarTop,
  SidebarHeader,
  MainTitle,
  SidebarButton,
  SidebarCategory,
  SidebarBottom,
  ContentContainer,
  Content,
  CreditsLeft,
  ProgressBarContainer,
  ProgressBarLine,
  GetMoreCredits,
  Divider,
  CloseButton,
  CloseButtonWrapper,
  BottomButtons,
  Description,
  GenerateButton,
  CancelButton,
  BackIcon,
  ButtonsContainer,
  Header,
  BackButton,
  BackButtonText,
  SidebarBlock,
  SidebarBlockTitle,
  DescriptionInfo,
  DescriptionTitle,
  DescriptionTitleText,
  DescriptionLabel,
  DescriptionSubtitle,
  CheckboxContainer,
  DescriptionIcon,
  TitleTextStarsIcon,
} from './styles';
import { useIntercom } from 'react-use-intercom';
import { useHistory } from 'react-router-dom';
import { Icon24, Icon48 } from '../../Icons/Icon';
import PromptTemplates from '../PromptTemplates';
import { usePrompts } from '../../Hooks/usePrompts';
import CustomTemplateCta from '../CustomTemplateCta';
import { CloudFile } from '../../Models';
import TemplatePreview from '../TemplatePreview';
import { useRecentPrompts } from '../../Hooks/useGeneratedSummaries';
import TemplatePlaceholder from './TemplatePlaceholder';
import CheckboxSlider from '../CheckboxSlider';

interface AITemplatesProps {
  type: 'transcript' | 'insight';
  confirmButtonText?: string;
  cancelButtonText?: string;
  currentFiles?: File[] | null;
  currentCloudFiles?: CloudFile[];
  withFiles?: boolean;
  withTitle?: boolean;
  hideSidebarTitle?: boolean;
  hideCloseButton?: boolean;
  onChangeTemplatesStep?(step: 'templates' | 'details'): void;
  onChooseTemplate?(template: PropmptTemplate | null): void;
  onConfirm(template: PropmptTemplate | null): void;
  onCancel(): void;
  onClickBack?(): void;
}

const AITemplates: FC<AITemplatesProps> = ({
  type,
  confirmButtonText,
  cancelButtonText,
  withFiles,
  withTitle,
  hideSidebarTitle,
  hideCloseButton,
  onChangeTemplatesStep,
  onChooseTemplate,
  onConfirm,
  onCancel,
  onClickBack,
}) => {
  const [, org] = useOrganization();
  const intercom = useIntercom();
  const history = useHistory();
  const [, prompts] = usePrompts({ promptType: type });
  const [, recentlyUsedPrompts] = useRecentPrompts({ type });

  const [currentTemplateId, setCurrentTemplateId] = useState<string | null>(null);
  const [currentCategory, setCurrentCategory] = useState('recommended');
  const [isFilteredBySmart, setIsFilteredBySmart] = useState(false);
  const [currentStep, setCurrentStep] = useState('templates');
  const [searchText, setSearchText] = useState('');
  const [enableSmartAnalysis, setEnableSmartAnalysis] = useState(true);

  const hasCredits = org.aiCredits > 0;
  const creditTotalAmount = org.aiCreditLimitTotal || org.aiCreditLimitMonthly;
  const currentScaleRatio = org.aiCredits / creditTotalAmount;
  const customPrompts = prompts?.filter((prompt: any) => !prompt?.isPublic) || [];

  useEffect(() => {
    if (!!searchText.length) {
      setCurrentCategory('all');
    }
  }, [searchText]);

  useEffect(() => {
    if (currentCategory !== 'all') {
      setSearchText('');
    }
  }, [currentCategory]);

  const createIntercomMessage = (type: 'credits' | 'prompts') => {
    if (type === 'credits') {
      intercom.showNewMessage('Hi, I want to get more AI credits. Please help me out.');
    } else {
      intercom.showNewMessage(
        `Hi, I'd like to learn more about adding a custom AI template to my workspace.`
      );
    }
  };

  const recommendedTemplates = useMemo(() => {
    let allTemplates =
      prompts.map((prompt: any) => {
        return {
          id: prompt?.id,
          title: prompt?.title,
          description: prompt?.description,
          icon: prompt?.icon || PROMPT_ICONS.get(prompt?.defaultIcon || '') || PromptDefaultIcon,
          isPublic: prompt?.isPublic,
          category: prompt?.category,
          isPopular: prompt?.isPopular,
          favoriteId: prompt?.favoriteId,
          createdAt: prompt?.createdAt,
          isSmart: prompt?.isSmart,
        };
      }) || [];

    if (isFilteredBySmart) allTemplates = allTemplates.filter((template) => template.isSmart);

    const recentTemplates =
      recentlyUsedPrompts
        .slice(0, 3)
        .map((templateId) => allTemplates.find((t) => t.id === templateId))
        .filter(Boolean)
        .map((prompt: any) => {
          return {
            id: prompt?.id,
            title: prompt?.title,
            description: prompt?.description,
            icon: prompt?.icon || PROMPT_ICONS.get(prompt?.defaultIcon || '') || PromptDefaultIcon,
            isPublic: prompt?.isPublic,
            category: prompt?.category,
            isPopular: prompt?.isPopular,
            favoriteId: prompt?.favoriteId,
            isSmart: prompt?.isSmart,
          };
        }) || [];

    let popularTemplates = allTemplates
      .filter((template: any) => template?.isPopular)
      .filter((template: any) => !recentTemplates.find((t) => t.id === template.id))
      .slice(0, 3);

    if (popularTemplates.length < 3) {
      const remainingTemplates = allTemplates.filter(
        (template: any) =>
          !recentTemplates.find((t) => t.id === template.id) &&
          !popularTemplates.find((t) => t.id === template.id)
      );
      popularTemplates = [
        ...popularTemplates,
        ...remainingTemplates.slice(0, 3 - popularTemplates.length),
      ];
    }

    const newTemplates = allTemplates
      .filter(
        (template: any) =>
          !recentTemplates.find((t) => t.id === template.id) &&
          !popularTemplates.find((t) => t.id === template.id)
      )
      .sort((a: any, b: any) => b.createdAt - a.createdAt)
      .slice(0, 9 - recentTemplates.length - popularTemplates.length);

    const firstTemplate =
      newTemplates[0] && newTemplates[0]?.createdAt - new Date().getTime() < 1000 * 60 * 60 * 24
        ? [newTemplates[0]]
        : [];

    return [
      ...firstTemplate,
      ...recentTemplates,
      ...popularTemplates,
      ...newTemplates.slice(firstTemplate.length),
    ];
  }, [prompts, recentlyUsedPrompts, isFilteredBySmart]);

  const getTemplatesByCategory = useMemo(() => {
    let promptTemplates =
      prompts?.map((prompt: any) => {
        return {
          id: prompt?.id,
          title: prompt?.title,
          description: prompt?.description,
          icon: prompt?.icon || PROMPT_ICONS.get(prompt?.defaultIcon || '') || PromptDefaultIcon,
          isPublic: prompt?.isPublic,
          category: prompt?.category,
          favoriteId: prompt?.favoriteId,
          isSmart: prompt?.isSmart,
        };
      }) || [];

    if (isFilteredBySmart) promptTemplates = promptTemplates.filter((template) => template.isSmart);

    if (searchText) {
      promptTemplates = promptTemplates.filter((template) =>
        searchText.split(' ').some((word) => {
          return (
            word.length &&
            (template.title.toLowerCase().includes(word.toLowerCase()) ||
              template.description.toLowerCase().includes(word.toLowerCase()))
          );
        })
      );
    }

    const customTemplates = promptTemplates?.filter((template: any) => !template?.isPublic);

    if (currentCategory === 'custom') return customTemplates;
    if (currentCategory === 'all') return promptTemplates;
    if (currentCategory === 'recommended') return recommendedTemplates;
    if (currentCategory === 'favorites')
      return promptTemplates.filter((template) => template.favoriteId);

    return promptTemplates.filter((template: any) => template.category === currentCategory);
  }, [currentCategory, prompts, recommendedTemplates, searchText, isFilteredBySmart]);

  const currentPlaceholderType = useMemo(() => {
    if (searchText) return 'search';
    if (currentCategory === 'custom') return 'custom';
    if (currentCategory === 'favorites') return 'favorite';
  }, [currentCategory, searchText]);

  const currentMessage = useMemo(() => {
    if (!hasCredits) return { title: 'You’re out of AI credits' };
    const currentTemplate = prompts.find((template: any) => template.id === currentTemplateId);
    if (!currentTemplate) return { title: 'Choose a template to summarize' };
    if (currentTemplate.isSmart) {
      if (enableSmartAnalysis)
        return {
          title: 'Posty',
          subtitle: 'Posty will highlight and tag this summary with AI.',
          label: '1 credit',
        };
      return {
        title: 'Posty',
        subtitle: 'Smart Analysis with AI by Posty is turned off. You’re in the driver seat!',
        label: '1 credit',
      };
    }
    return {
      title: 'Posty',
      subtitle: "Smart Analysis with AI by Posty isn't supported on this Template yet.",
      label: '1 credit',
    };
  }, [currentTemplateId, hasCredits, enableSmartAnalysis, prompts]);

  const isCurrentTemplateSmart =
    prompts.find((template: any) => template.id === currentTemplateId)?.isSmart || false;

  return (
    <>
      {currentStep === 'details' && (
        <Header>
          <BackButton
            onClick={() => {
              setCurrentStep('templates');
              onChangeTemplatesStep?.('templates');
            }}
          >
            <Icon24.ChevronBack />
            <BackButtonText>Back to Templates</BackButtonText>
          </BackButton>
          <CloseButton onClick={onCancel}>
            <Icon24.Close />
          </CloseButton>
        </Header>
      )}
      <ContentWrapper>
        {currentStep === 'templates' && (
          <>
            <Sidebar>
              <SidebarTop>
                {!withFiles && !hideSidebarTitle && (
                  <SidebarHeader>
                    {onClickBack && (
                      <BackIcon onClick={onClickBack}>
                        <Icon24.ArrowLeft />
                      </BackIcon>
                    )}
                    <MainTitle>Templates</MainTitle>
                  </SidebarHeader>
                )}

                <SidebarBlock>
                  <SidebarCategory>
                    <SidebarButton
                      onClick={() => setCurrentCategory('recommended')}
                      active={currentCategory === 'recommended'}
                    >
                      Recommended
                    </SidebarButton>
                  </SidebarCategory>

                  <>
                    <SidebarCategory>
                      <SidebarButton
                        onClick={() => setCurrentCategory('favorites')}
                        active={currentCategory === 'favorites'}
                      >
                        Favorites
                      </SidebarButton>
                    </SidebarCategory>
                  </>

                  <SidebarCategory>
                    <SidebarButton
                      onClick={() => setCurrentCategory('all')}
                      active={currentCategory === 'all'}
                    >
                      All templates
                    </SidebarButton>
                  </SidebarCategory>
                </SidebarBlock>

                <SidebarBlock>
                  <SidebarBlockTitle>use cases</SidebarBlockTitle>

                  {CATEGORIES.map((category) => {
                    if (!prompts.some((template: any) => template.category === category.id))
                      return null;
                    return (
                      <SidebarCategory key={category.id}>
                        <SidebarButton
                          onClick={() => setCurrentCategory(category.id)}
                          active={currentCategory === category.id}
                        >
                          {category.title}
                        </SidebarButton>
                      </SidebarCategory>
                    );
                  })}
                </SidebarBlock>

                <SidebarBlock>
                  <SidebarBlockTitle>custom</SidebarBlockTitle>
                  <SidebarCategory>
                    <SidebarButton
                      onClick={() => setCurrentCategory('custom')}
                      active={currentCategory === 'custom'}
                    >
                      {org.name || 'Custom'} templates
                    </SidebarButton>
                  </SidebarCategory>
                </SidebarBlock>
              </SidebarTop>

              <SidebarBottom>
                <CreditsLeft>
                  {hasCredits ? `${org.aiCredits} credits left` : 'You’re out of AI credits'}
                </CreditsLeft>
                <ProgressBarContainer>
                  <ProgressBarLine scaleRatio={currentScaleRatio} />
                </ProgressBarContainer>

                <GetMoreCredits
                  onClick={
                    hasCredits
                      ? () => createIntercomMessage('credits')
                      : () => history.push('/settings/plans')
                  }
                >
                  {hasCredits ? 'Get more credits' : 'Upgrade for more credits'}
                </GetMoreCredits>
              </SidebarBottom>
            </Sidebar>

            <ContentContainer>
              {!withFiles && !hideCloseButton && (
                <CloseButtonWrapper>
                  <CloseButton onClick={onCancel}>
                    <Icon24.Close />
                  </CloseButton>
                </CloseButtonWrapper>
              )}
              <Content smallPaddings={withTitle || hideCloseButton}>
                {prompts && prompts.length > 0 && (
                  <>
                    <PromptTemplates
                      templates={getTemplatesByCategory}
                      currentTemplateId={currentTemplateId}
                      currentCategoryId={currentCategory}
                      withSmartTemplates={type !== 'insight'}
                      onFilterBySearch={(text) => setSearchText(text)}
                      onFilterByType={(type) => setIsFilteredBySmart(type === 'smart')}
                      onClickDetails={(template) => {
                        onChangeTemplatesStep?.('details');
                        setCurrentStep('details');
                        setCurrentTemplateId(template.id);
                      }}
                      onChange={(template) => {
                        onChooseTemplate?.(template);
                        setCurrentTemplateId(template.id);
                      }}
                    />

                    {currentCategory === 'all' &&
                      !customPrompts.length &&
                      !!getTemplatesByCategory.length && <Divider />}

                    {((!getTemplatesByCategory.length && !!searchText.length) ||
                      (currentCategory === 'custom' && !customPrompts.length) ||
                      (currentCategory === 'favorites' && !getTemplatesByCategory.length)) && (
                      <TemplatePlaceholder type={currentPlaceholderType} />
                    )}

                    {currentCategory === 'all' &&
                      !customPrompts.length &&
                      !!getTemplatesByCategory.length && <CustomTemplateCta />}
                  </>
                )}
              </Content>

              {!withFiles && (
                <BottomButtons>
                  <Description alignCenter={type === 'insight'}>
                    <DescriptionIcon>
                      {isCurrentTemplateSmart && enableSmartAnalysis ? (
                        <Icon48.PostyMedium />
                      ) : (
                        <Icon48.PostyMediumGray />
                      )}
                    </DescriptionIcon>
                    <DescriptionInfo>
                      <DescriptionTitle>
                        {isCurrentTemplateSmart && (
                          <CheckboxContainer>
                            <CheckboxSlider
                              checked={enableSmartAnalysis}
                              onChange={setEnableSmartAnalysis}
                            />
                          </CheckboxContainer>
                        )}
                        <DescriptionTitleText
                          isSmart={isCurrentTemplateSmart && enableSmartAnalysis}
                        >
                          <TitleTextStarsIcon
                            isSmart={isCurrentTemplateSmart && enableSmartAnalysis}
                          >
                            <Icon24.SmartStars />
                          </TitleTextStarsIcon>
                          {type === 'insight'
                            ? "Posty isn't supported on Insight templates yet"
                            : currentMessage.title}
                        </DescriptionTitleText>
                        {type !== 'insight' && (
                          <DescriptionLabel isSmart={isCurrentTemplateSmart && enableSmartAnalysis}>
                            {currentMessage.label}
                          </DescriptionLabel>
                        )}
                      </DescriptionTitle>
                      {type !== 'insight' && (
                        <DescriptionSubtitle
                          isSmart={isCurrentTemplateSmart && enableSmartAnalysis}
                        >
                          {currentMessage.subtitle}
                        </DescriptionSubtitle>
                      )}
                    </DescriptionInfo>
                  </Description>

                  <ButtonsContainer>
                    <GenerateButton
                      onClick={() => {
                        if (type !== 'insight') {
                          localStorage.setItem(
                            'enableSmartAnalysis',
                            (enableSmartAnalysis && isCurrentTemplateSmart).toString()
                          );
                        }

                        const currentTemplate = prompts.find(
                          (template) => template.id === currentTemplateId
                        );
                        onConfirm(
                          ({
                            ...currentTemplate,
                            icon:
                              currentTemplate?.icon ||
                              PROMPT_ICONS.get(currentTemplate?.defaultIcon || '') ||
                              PromptDefaultIcon,
                          } as PropmptTemplate) || null
                        );
                      }}
                      disabled={!hasCredits || !currentTemplateId}
                      isSmart={isCurrentTemplateSmart && enableSmartAnalysis}
                    >
                      {confirmButtonText ? confirmButtonText : 'Start Analysis'}
                    </GenerateButton>
                    <CancelButton onClick={onCancel}>
                      {cancelButtonText ? cancelButtonText : 'Cancel'}
                    </CancelButton>
                  </ButtonsContainer>
                </BottomButtons>
              )}
            </ContentContainer>
          </>
        )}

        {currentStep === 'details' && currentTemplateId && (
          <TemplatePreview templateId={currentTemplateId} />
        )}
      </ContentWrapper>
    </>
  );
};

export default AITemplates;
