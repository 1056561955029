import styled, { css } from 'styled-components';
import { breakpoint } from '../../Shared';
import customTemplateImg from '../../assets/empty-states/custom.png';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
  `}
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #a39ea9;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: -1;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 999;
  `}
`;

export const ContentWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const Sidebar = styled.div`
  width: 250px;
  border-right: 1px solid #d7d3dc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;

  ${breakpoint.Mobile`
    display: none;
  `}
`;

export const SidebarTop = styled.div`
  width: 100%;
`;

export const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 20px 20px 0;
`;

export const BackIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 20px;
  cursor: pointer;
`;

export const MainTitle = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
`;

export const SidebarCategory = styled.div`
  padding: 5px 20px;
`;

export const SidebarButton = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 6px;

  ${({ active }) =>
    active &&
    `
    background-color: rgba(56, 33, 82, 0.1);
  `}
`;

export const SidebarBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 20px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div<{ smallPaddings?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${({ smallPaddings }) => (smallPaddings ? '0 20px 30px' : '16px 20px 30px')};
  overflow-y: auto;
`;

export const BottomButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-top: 1px solid #d7d3dc;

  ${breakpoint.Mobile`
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GenerateButton = styled.div<{ disabled?: boolean; isSmart?: boolean }>`
  position: relative;
  padding: 12px 10px;
  background: ${({ isSmart }) =>
    isSmart ? 'linear-gradient(95.7deg, #8E84EE 0.45%, #D2CEF8 92%)' : '#382152'};
  border-radius: 4px;
  cursor: pointer;
  color: ${({ isSmart }) => (isSmart ? 'rgba(59, 38, 81, 1)' : '#ffffff')};
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 10px;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    background: rgba(59, 38, 81, 0.4);
  `}
`;

export const CancelButton = styled.div`
  padding: 11px 10px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 1px 0px #d7d3dc, 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
`;

export const NewBadge = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin-left: 10px;
  padding: 3px 10px;
  background: linear-gradient(90deg, #d1deff 0%, #d8c0f8 100%);
  border-radius: 6px;
`;

export const CreditsLeft = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
`;

export const GetMoreCredits = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #d9d9d9;
  margin-bottom: 8px;
`;

export const ProgressBarLine = styled.div<{ scaleRatio: number }>`
  height: 4px;
  width: 100%;
  background-color: #3b2651;
  transform-origin: 0 center;
  transform: scaleX(${({ scaleRatio }) => (scaleRatio > 1 ? 1 : scaleRatio)});
  transition: transform 0.2s ease;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7d3dc;
  margin: 20px 0;
  flex-shrink: 0;
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  padding: 20px 20px 4px;
  border-radius: 0 6px 0 0;
`;

export const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
  color: rgba(59, 38, 81, 0.5);
  opacity: 0.4;
`;

export const Header = styled.div`
  width: 100%;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BackButtonText = styled.div`
  margin-left: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

export const TemplatesTitle = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const TemplatesSubtitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const SidebarBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 24px;
`;

export const SidebarBlockTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(59, 38, 81, 0.4);
  padding: 0 30px;
`;

export const TemplatePlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const TemplatePlaceholderImage = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${customTemplateImg});
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 16px;
`;

export const TemplatePlaceholderTitle = styled.div`
  font-size: 18px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const TemplatePlaceholderDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  width: 100%;
  max-width: 490px;
  margin-bottom: 16px;
  text-align: center;
`;

export const CustomTemplateButtonText = styled.div`
  margin-left: 5px;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TemplatePlaceholderBadge = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.6);
  margin-bottom: 16px;
`;

export const TemplatePlaceholderBadgeIcon = styled.div`
  margin-right: 5px;
`;

export const TemplatePlaceholderLearnMore = styled.div`
  margin-top: 16px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(59, 38, 81, 0.4);
  cursor: pointer;
`;

export const Description = styled.div<{ alignCenter: boolean }>`
  display: flex;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-right: 20px;

  ${breakpoint.Mobile`
    margin-bottom: 10px;
  `}
`;

export const DescriptionInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const DescriptionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const DescriptionTitleText = styled.div<{ isSmart: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 5px;

  ${({ isSmart }) =>
    isSmart
      ? css`
          background: linear-gradient(
            90deg,
            rgba(142, 132, 238, 0.6) 0%,
            rgba(53, 26, 138, 0.6) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `
      : css`
          color: rgba(59, 38, 81, 0.4);
        `}
`;

export const DescriptionLabel = styled.div<{ isSmart: boolean }>`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;

  ${({ isSmart }) =>
    isSmart
      ? css`
          background: linear-gradient(
            90deg,
            rgba(142, 132, 238, 0.6) 0%,
            rgba(53, 26, 138, 0.6) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `
      : css`
          color: rgba(59, 38, 81, 0.4);
        `}
`;

export const DescriptionSubtitle = styled.div<{ isSmart: boolean }>`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  ${({ isSmart }) =>
    isSmart
      ? css`
          background: linear-gradient(90deg, #351a8a 0%, #8e84ee 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `
      : css`
          color: rgba(59, 38, 81, 0.4);
        `}
`;

export const TitleTextStarsIcon = styled.div<{ isSmart: boolean }>`
  color ${({ isSmart }) => (isSmart ? '#A69CDE' : 'rgba(59, 38, 81, 0.4)')};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2px;
`;

export const DescriptionIcon = styled.div``;
