/* eslint-disable @typescript-eslint/no-empty-function */
import { Editor, Range } from '@tiptap/react';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import PopupMessage from '../Components/PopupMessage';
import Spinner from '../Components/Spinner';
import { fillerWords } from '../Consts/fillerWords';
import { useOpenAIApi } from '../Hooks/useOpenAI';
import { useCreateTag } from '../Hooks/useTags';
import { useAddParticipantLink, useUpdateParticipantLink } from '../Hooks/useTranscripts';
import { Icon24 } from '../Icons/Icon';
import { TagWithDetails } from '../Models';
import { HighlightsContext } from './HighlightsContext';
import { TSpeakerBlock } from './SpeakersContext';

interface EditorContextProps {
  editor: Editor | null;
  highlightsGenerating: boolean;
  showSearchAndReplace: boolean;
  documentParticipantId: string;
  setShowSearchAndReplace: (value: boolean) => void;
  setEditorContext(editor: Editor): void;
  updateSpeakerNames(editingName: string, newName: string): void;
  linkParticipant(
    transcriptId: string,
    speakerBlock: TSpeakerBlock,
    participantRowId: string,
    newParticipant: any
  ): Promise<void>;
  highlightAll(
    dashboardId: string,
    currentTags: TagWithDetails[],
    options?: { withTags?: boolean; callback?(): void; message?: string }
  ): Promise<void>;
  setDocumentParticipantId(id: string): void;
  updateDocumentHighlightsParticipant(participantId: string): void;
  cleanFillerWords(): void;
  cleanPII(): void;
}

export const EditorContext = React.createContext<EditorContextProps>({
  editor: null,
  highlightsGenerating: false,
  documentParticipantId: '',
  showSearchAndReplace: false,
  setShowSearchAndReplace: () => {},
  setEditorContext: () => {},
  updateSpeakerNames: () => {},
  linkParticipant: async () => {},
  highlightAll: async () => {},
  setDocumentParticipantId: () => {},
  updateDocumentHighlightsParticipant: () => {},
  cleanFillerWords: () => {},
  cleanPII: () => {},
});

interface EditorContextProviderProps {
  type: 'transcript' | 'document';
  dashboardId: string;
  transcriptId?: string;
  documentId?: string;
  children: React.ReactNode;
}

type THighlightAllResult = {
  text: string;
  tags: string[];
};

export const EditorContextProvider: FC<EditorContextProviderProps> = ({
  type,
  children,
  dashboardId,
  transcriptId,
  documentId,
}) => {
  const [editor, setEditor] = useState<Editor | null>(null);
  const [addParticipantLink] = useAddParticipantLink();
  const [updateParticipantLink] = useUpdateParticipantLink();
  const { generateHighlights, checkTaskStatus } = useOpenAIApi();
  const { addHighlight } = useContext(HighlightsContext);
  const [highlightsGenerating, setHighlightsGenerating] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<string | null>(null);
  const [generatingMessage, setGeneratingMessage] = useState<string | null>(null);
  const [failMessage, setFailMessage] = useState<string | null>(null);
  const [documentParticipantId, setDocumentParticipantId] = useState('');
  const [showSearchAndReplace, setShowSearchAndReplace] = useState(false);

  const [taskId, setTaskId] = useLocalStorage(
    `highlightsGeneration_${dashboardId}_${transcriptId || documentId}`,
    ''
  );
  const [generationOptions, setGenerationOptions] = useState<{
    withTags?: boolean;
    callback?(): void;
    message?: string;
  } | null>(null);
  const [currentTags, setCurrentTags] = useState<TagWithDetails[]>([]);
  const createTag = useCreateTag();

  useEffect(() => {
    if (!editor || !taskId) return;
    const processResult = async (highlights: THighlightAllResult[]) => {
      const createdTags = generationOptions?.withTags
        ? await processHighlightAllResult(highlights, dashboardId, currentTags)
        : [];

      let highlightsNumber = 0;
      const highlightPositions: Range[] = [];
      editor.state.doc.content.nodesBetween(0, editor.state.doc.content.size, (node, pos) => {
        if (node.type.name === 'highlightTag') {
          highlightPositions.push({ from: pos, to: pos + node.nodeSize });
        }
      });

      highlights?.forEach((item) => {
        const text = item.text;
        const strToFind = text[text.length - 1].match(/[.!;,?:]/g)
          ? text.slice(0, text.length - 1)
          : text;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { result } = editor.commands.findNextOccurance(strToFind);
        if (!result.length) {
          console.log('No text found', item.text);
          return;
        }

        const generatedId = Math.floor(Math.random() * Date.now()).toString(16);
        let index = 0;

        const resultText = result
          .map((range: Range) => {
            return editor.state.doc.textBetween(range.from, range.to, '#');
          })
          .join(' ');

        if (
          !item.text.toLowerCase().includes(resultText.toLowerCase()) ||
          resultText.length / item.text.length < 0.8
        ) {
          console.log('No text found', item.text);
          return;
        }

        ++highlightsNumber;

        result.forEach((range: Range) => {
          const hasIntersection = highlightPositions.some((highlight) => {
            return (
              (range.from + index * 2 >= highlight.from &&
                range.from + index * 2 <= highlight.to) ||
              (range.to + index * 2 >= highlight.from && range.to + index * 2 <= highlight.to)
            );
          });

          if (hasIntersection) return;

          const highlightTags: TagWithDetails[] = generationOptions?.withTags
            ? (item?.tags
                ?.map(
                  (tag) =>
                    currentTags.find((t) => t.name === tag) ||
                    createdTags.find((t) => t.name === tag)
                )
                .filter(Boolean) as TagWithDetails[]) || []
            : [];

          highlightPositions.push({ from: range.from + index * 2, to: range.to + index * 2 });

          editor.commands.setTextSelection({
            from: range.from + index * 2,
            to: range.to + index * 2,
          });

          let speakeId = '';
          const selectedTextAnchor = editor.view.state.selection.anchor;
          editor.state.doc.content.nodesBetween(0, editor.state.doc.content.size, (node, pos) => {
            if (
              node.type.name === 'speaker' &&
              selectedTextAnchor >= pos &&
              selectedTextAnchor <= pos + node.nodeSize
            ) {
              speakeId = node.attrs.id;
            }
          });

          if (generationOptions?.withTags) {
            addHighlight({
              entityId: generatedId,
              texts: editor.state.doc
                .textBetween(range.from + index * 2, range.to + index * 2, '#')
                .split('#'),
              tags: highlightTags,
              addedToAnalysis: false,
              speakerId: speakeId || documentParticipantId || '',
              createdByAi: true,
              tagsHighlights: {
                deleteOthers: true,
                create: highlightTags.map((tag) => ({ tagId: tag.id })) || [],
              },
            });
          }

          editor
            .chain()
            .focus()
            .setHighlightTag({
              id: generatedId,
              type: 'highlight',
              speakerId: speakeId || documentParticipantId || '',
              createdByAi: true,
              serialNumber: index,
            })
            .run();

          index++;
        });
        generationOptions?.callback && generationOptions.callback();
      });
      setHighlightsGenerating(false);
      setGeneratingMessage(null);
      setTaskId('');
      setGenerationOptions(null);
      setCurrentTags([]);
      handleShowSuccessMessage({ highlights: highlightsNumber, tags: createdTags.length });
    };

    if (taskId) {
      const intervalId = setInterval(async () => {
        setHighlightsGenerating(true);
        handleShowGeneratingMessage();
        const data = await checkTaskStatus(taskId);
        if (data.status === 'ready') {
          clearInterval(intervalId);
          const result = JSON.parse(data.result);
          processResult(result);
          setTaskId('');
        } else if (data.status === 'error') {
          handleShowFailMessage();
          setHighlightsGenerating(false);
          clearInterval(intervalId);
          setTaskId('');
          setGenerationOptions(null);
          setCurrentTags([]);
        }
      }, 2000);
    }
  }, [taskId, editor, currentTags, generationOptions, dashboardId]);

  const updateSpeakerNames = (editingName: string, newName: string) => {
    if (!editor) return;
    let editorUpdateChain = editor.chain();
    editor.state.doc.content.nodesBetween(
      0,
      editor.state.doc.content.size,
      (node: any, pos: number) => {
        if (
          (node.type.name === 'speaker' && node.attrs.speakerName === editingName) ||
          (!node.attrs.speakerName && editingName === 'Unknown Speaker')
        ) {
          const newNode = {
            ...node.toJSON(),
            attrs: { ...node.attrs, speakerName: newName },
          };

          editorUpdateChain = editorUpdateChain.insertContentAt(
            {
              from: pos,
              to: pos + node.nodeSize,
            },
            newNode
          );
        }
      }
    );
    editorUpdateChain.run();
  };

  const linkParticipant = async (
    transcriptId: string,
    speakerBlock: TSpeakerBlock,
    participantRowId: string,
    newParticipant: any
  ) => {
    if (!speakerBlock.id) return;
    if (!participantRowId) {
      await addParticipantLink(transcriptId, speakerBlock.id, newParticipant.id);
    } else {
      await updateParticipantLink(
        participantRowId,
        transcriptId,
        speakerBlock.id,
        newParticipant.id
      );
    }
  };

  const getTranscriptContentForHighlights = () => {
    if (!editor) return '';
    let content = '';
    editor.state.doc.content.nodesBetween(0, editor.state.doc.content.size, (node: any) => {
      if (node.type.name === 'summary') {
        content += `${node?.textContent || ''}\n\n`;
      }

      if (node.type.name === 'speaker') {
        content += `${node.attrs.speakerName}: ${node?.textContent || ''}\n\n`;
      }
    });
    return content;
  };

  const getDocumentContentForHighlights = () => {
    return editor ? editor.view.dom.innerText : '';
  };

  const handleShowSuccessMessage = ({ highlights, tags }: { highlights: number; tags: number }) => {
    setShowSuccessMessage(
      `Posty created new ${highlights} new highlights${tags ? ` and ${tags} new tags` : '.'}`
    );
    setTimeout(() => {
      setShowSuccessMessage(null);
    }, 4000);
  };

  const handleShowGeneratingMessage = () => {
    setGeneratingMessage('Posty is working on highlights and tags. This could take a minute.');
  };

  const handleShowFailMessage = () => {
    setFailMessage('Something went wrong with Posty. Try again');
    setTimeout(() => {
      setFailMessage(null);
    }, 3000);
  };

  const processHighlightAllResult = async (
    results: THighlightAllResult[],
    dashboardId: string,
    currentTags: TagWithDetails[]
  ) => {
    const newTags: string[] = [];
    const recievedTags =
      results
        .map((result) => result.tags?.map((tag) => tag.trim()))
        .flat()
        .filter(Boolean) || [];

    recievedTags.forEach((tag) => {
      const tagExists = currentTags.find((t) => t.name === tag.trim());
      if (!tagExists) {
        newTags.push(tag.trim());
      }
    });

    const uniqeTags = [...new Set(newTags)];
    const createdTags = [];
    for (const tag of uniqeTags) {
      const newTag = await createTag({ name: tag, dashboardId: dashboardId, createdWithAi: true });
      createdTags.push(newTag);
    }
    return createdTags;
  };

  const highlightAll = async (
    dashboardId: string,
    currentTags: TagWithDetails[],
    options?: { withTags?: boolean; callback?(): void; message?: string }
  ) => {
    if (!editor) return;
    setCurrentTags(currentTags);
    setGenerationOptions(options || null);
    setHighlightsGenerating(true);
    handleShowGeneratingMessage();
    const content =
      type === 'transcript'
        ? getTranscriptContentForHighlights()
        : getDocumentContentForHighlights();
    const { taskId, success } = await generateHighlights({
      content,
      userMessage: options?.message || '',
      dashboardId: dashboardId,
      enableTags: !!options?.withTags,
    });
    if (!success) {
      setCurrentTags([]);
      setGenerationOptions(null);
      setHighlightsGenerating(false);
      setGeneratingMessage(null);
      handleShowFailMessage();
      return;
    }
    setTaskId(taskId);
  };

  const updateDocumentHighlightsParticipant = (participantId: string): void => {
    if (!editor) return;

    let editorUpdateChain = editor.chain();
    editor.state.doc.content.nodesBetween(0, editor.state.doc.content.size, (node, pos) => {
      if (node.type.name === 'highlightTag') {
        const newNode = {
          ...node.toJSON(),
          attrs: { ...node.attrs, speakerId: participantId },
        };

        editorUpdateChain = editorUpdateChain.insertContentAt(
          {
            from: pos,
            to: pos + node.nodeSize,
          },
          newNode
        );
      }
    });
    setTimeout(() => editorUpdateChain.run(), 0);
  };

  const cleanFillerWords = () => {
    if (!editor) return;
    editor.commands.setFillerWords(fillerWords);
    editor.commands.cleanAll();
    editor.commands.setFillerWords([]);
  };

  const cleanPII = () => {
    if (!editor) return;
    editor?.commands.togglePIISearch(true);
    editor?.commands.cleanPII();
  };

  return (
    <EditorContext.Provider
      value={{
        editor,
        highlightsGenerating,
        documentParticipantId,
        showSearchAndReplace,
        setShowSearchAndReplace,
        updateSpeakerNames,
        linkParticipant,
        highlightAll,
        setEditorContext: setEditor,
        setDocumentParticipantId,
        updateDocumentHighlightsParticipant,
        cleanFillerWords,
        cleanPII,
      }}
    >
      {generatingMessage && (
        <PopupMessage
          message={generatingMessage}
          type="message"
          icon={<Spinner size="small" />}
          messageStyle="posty"
        />
      )}

      {showSuccessMessage && (
        <PopupMessage icon={<Icon24.TickCircle />} message={showSuccessMessage} type="success" />
      )}

      {failMessage && <PopupMessage message={failMessage} type="error" />}
      {children}
    </EditorContext.Provider>
  );
};
