// import * as FullStory from '@fullstory/browser';

declare global {
  interface Window {
    dataLayer?: any;
  }
}

const useAnalytics = () => {
  window.dataLayer = window.dataLayer || [];

  const analytics = {
    sendEvent: (event: string, data?: Record<string, any>) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event,
          ...data,
        });
      } else {
        console.log('GTM not initialized ', 'event: ', event, 'data: ', data);
      }
      // FullStory.event(event, data || {});
    },
  };

  return { analytics };
};

export default useAnalytics;
