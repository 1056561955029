import React from 'react';
import Button from '../../../Components/Button';
import { TrashIcon } from '@heroicons/react/outline';
import { TableCell, TableRow } from '../../../Components/TableSimple';
import {
  getIntegrationLogo,
  getIntegrationLongDescription,
  getPublishableUrl,
} from '../utils/integrationsUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { REDIRECT_URI } from '../utils/constants';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_INTEGRATIONS } from '../../../GraphQL/queries';
import { AUTHORIZE_ONEDRIVE, DELETE_INTEGRATION } from '../../../GraphQL/mutations';
import { ID } from '../../../Models';
import Loader from '../../Loader';
import SecondaryToolbar from '../../SecondaryToolbar';
import { Integration } from '../../../Hooks/useFetchIntegrations';
import { useFetchSlackWebhooks } from '../../../Hooks/useSlackWebhooks';
import { clientId, defaultMSALInstance, defaultRedirectURI, login } from '../utils/msal';
import { OIDC_DEFAULT_SCOPES } from '@azure/msal-browser';

export default function IntegrationDetails(): JSX.Element {
  const { user } = useAuth0();
  const { integrationName } = useParams<{ integrationName: string }>();
  const [deleteMutation] = useMutation(DELETE_INTEGRATION);
  const [authorizeOneDriveMutation] = useMutation(AUTHORIZE_ONEDRIVE);
  const { data, loading, refetch } = useQuery(FETCH_INTEGRATIONS);
  const {refetch: refetchSlackWebhooks} = useFetchSlackWebhooks();
  const connection = data?.integrations?.find(
    (x: Integration) =>
      x.type === integrationName.toLocaleLowerCase() &&
      (x.createdBy === user?.['https://notably.ai/claims/user_id'] ||
        // Slack integration is not scoped to a specific user
        x.type === 'slack')
  );

  async function handleDelete(id: ID) {
    await deleteMutation({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: FETCH_INTEGRATIONS,
        },
      ],
    });
    await refetch().then(refetchSlackWebhooks);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          {`Settings / Integrations / ${integrationName}`}
        </div>
      </SecondaryToolbar>
      <TableRow hover={false}>
        <TableCell>
          <div className={'flex-col p-5 max-w-[80%]'}>
            <div className="flex">
              <img
                src={getIntegrationLogo(integrationName)}
                alt="SVG Image"
                style={{ height: '48px', width: '48px' }}
              />
            </div>
            <div className="flex-col py-5">
              <div className="flex items-center">
                <span className="font-bold">{integrationName}</span>
                {connection && (
                  <span className="bg-[#A0E4CA] ml-2 text-brand-purple text-sm font-semibold leading-5 px-1 rounded-sm gap-10">
                    Connected
                  </span>
                )}
              </div>
              <div className={'flex flex-col text-sm py-1 mb-3 whitespace-normal break-words'}>
                <span>{getIntegrationLongDescription(integrationName)}</span>
              </div>
              <TableCell>
                {connection ? (
                  <Button
                    onClick={() => handleDelete(connection.id)}
                    type={'secondary'}
                    size={'sm'}
                    icon={<TrashIcon className={'w-4 h-4 mr-1'} />}
                  >
                    Disconnect
                  </Button>
                ) : (
                  <Button
                    onClick={async () => {
                      if (integrationName.toLowerCase() === 'onedrive') {
                        const response = await login();
                        await authorizeOneDriveMutation({
                          variables: { code: response.accessToken, redirectUri: defaultRedirectURI },
                        });
                        await refetch();
                      } else {
                        window.location.href = getPublishableUrl(integrationName, REDIRECT_URI);
                      }
                    }}
                    type={'secondary'}
                    size={'sm'}
                  >
                    Connect
                  </Button>
                )}
              </TableCell>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}
