import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const FileItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const FileCardItem = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 20px;
  border: 1px solid #d7d3dc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  margin: 0 10px 10px 0;
`;

export const FileCardItemText = styled.div`
  max-width: 250px;
  word-wrap: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const DeleteFileButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
`;

export const AddFileButton = styled.div`
  width: 24px;
  height: 24px;
  background-color: rgba(56, 33, 82, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(59, 38, 81, 1);
  cursor: pointer;
`;
