import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useApolloClient, useMutation } from '@apollo/client';
import { REGISTER } from '../../GraphQL/mutations';
import { toast } from 'react-toastify';
import NotablyLogo from '../../Icons/NotablyLogo';
import { useHistory } from 'react-router';
import useUsers from '../../Hooks/useUsers';

import { Container, LogoWrapper, ContentContainer, OnboardingWindowWrapper } from './styles';
import { useOrganization } from '../../Hooks/useOrganization';
import useBoards from '../../Hooks/useBoards';
import useMediaQuery from '../../Hooks/useMediaQuery';
import CheckAccountStep from './Steps/CheckAccountStep';
import StepProgress from '../StepProgress/StepProgress';
import InviteStep from './Steps/InviteStep';
import ImportDataStep from './Steps/ImportDataStep';
import ChooseTemplateStep from './Steps/ChooseTemplateStep';
import useUpload from '../../Hooks/useUpload';
import { isDocx, isPdf, isTxt, isVideoOrAudio } from '../../Utils/fileTypes';
import { UploadFilesContext } from '../../Context/UploadFilesContext';
import { TPrompt } from '../../Hooks/usePrompts';
import { GET_SAMPLE_TRANSCRIPT } from '../../GraphQL/queries';
import { nanoid } from 'nanoid';
import useFiles from '../../Hooks/useFiles';
import useTranscripts from '../../Hooks/useTranscripts';
import { Tag } from '../../Models';
import IntroductionStep from './Steps/IntroductionStep';
import useAnalytics from '../../Hooks/useAnalytics';
import { PropmptTemplate } from '../../Consts/promptTemplates';

function generateWorkspaceName(s?: string) {
  return (
    s
      ?.toLowerCase()
      .replace(/[^a-z0-9]+/gi, '-')
      .replace(/[-]+$/, '')
      .replace(/^[-]+/, '') ?? ''
  );
}

export default function Onboarding(): JSX.Element | null {
  const { analytics } = useAnalytics();
  const { user: auth0user, loginWithRedirect } = useAuth0();
  const history = useHistory();
  const { currentUser } = useUsers();
  const [, org] = useOrganization();
  const [, user] = currentUser();
  const {
    handleFileUpload,
    handleTranscriptUpload,
    handleTxtUpload,
    handlePdfUpload,
    handleDocxUpload,
  } = useUpload();
  const { uploadFile, removeUploadingFile } = useContext(UploadFilesContext);
  const client = useApolloClient();
  const { createFile } = useFiles();
  const { createTranscript } = useTranscripts();

  const userInvited = auth0user?.['https://notably.ai/claims/user_invited'] === true;

  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);

  const onboardingStatus = localStorage.getItem('onboardingStatus');

  const [stepNumber, setStepNumber] = useState<number>(
    onboardingStatus === 'pending_step_2' ? 2 : 0
  );

  const [registerMutation, { loading, error }] = useMutation(REGISTER);
  const { createBoard } = useBoards();
  const { isPhone } = useMediaQuery();

  const totalSteps = userInvited || user?.role === 'viewer' ? 1 : 4;

  useEffect(() => {
    if (stepNumber === 1 && auth0user && user && org && !userInvited) {
      history.push(`/`);
    }
  }, [stepNumber, org, auth0user, user, history, userInvited]);

  const saveTask = (
    dashboardId: string,
    transcriptId: string,
    template: PropmptTemplate | null | undefined
  ) => {
    if (!template) return;
    localStorage.setItem(
      `TASKS_${dashboardId}_${transcriptId}`,
      JSON.stringify([
        {
          id: 'summarize',
          name: 'Summarize',
          creditsPrice: 1,
          description: 'Automatically summarize data using our library of AI templates.',
          tagsEnabled: true,
        },
      ])
    );
  };

  const finishOnboarding = async (
    template?: PropmptTemplate | null,
    options?: { withoutUploading?: boolean; skipAllSteps?: boolean }
  ) => {
    localStorage.removeItem('onboardingStatus');

    if (!userInvited && user?.role !== 'viewer' && !options?.withoutUploading) {
      const dashboard = await createBoard('');

      analytics.sendEvent('CreateProject in onboarding', {
        id: dashboard.id,
      });

      if (options?.skipAllSteps) {
        analytics.sendEvent('Skipped all steps', {
          origin: 'Onboarding',
        });
        if (!isPhone) {
          history.push(`/projects/${dashboard.id}/data`);
        } else {
          history.push(`/`);
        }
        return;
      }

      if (currentFiles?.length) {
        currentFiles.forEach((currentFile) => {
          if (isVideoOrAudio(currentFile.name)) {
            analytics.sendEvent('Uploaded video during onboarding', {
              origin: 'Onboarding',
            });

            uploadFile(dashboard.id, currentFile, handleFileUpload, {
              language: 'en-US',
              aiTemplate: template?.id,
              callback: (fileId: string) => saveTask(dashboard.id, fileId, template),
            });
            return;
          }

          removeUploadingFile(currentFile.name);

          if (isDocx(currentFile.name)) {
            analytics.sendEvent('Uploaded doc during onboarding', {
              origin: 'Onboarding',
            });
            handleDocxUpload(dashboard.id, currentFile, {
              redirectAfterUpload: currentFiles.length === 1,
              template: template,
              callback: (fileId: string) => saveTask(dashboard.id, fileId, template),
            });
            return;
          }

          if (isPdf(currentFile.name)) {
            analytics.sendEvent('Uploaded pdf during onboarding', {
              origin: 'Onboarding',
            });
            handlePdfUpload(dashboard.id, currentFile, {
              redirectAfterUpload: currentFiles.length === 1,
              template: template,
              callback: (fileId: string) => saveTask(dashboard.id, fileId, template),
            });
            return;
          }

          if (isTxt(currentFile.name)) {
            handleTxtUpload(dashboard.id, currentFile, {
              redirectAfterUpload: currentFiles.length === 1,
              template: template,
              callback: (fileId: string) => saveTask(dashboard.id, fileId, template),
            });
            return;
          }

          analytics.sendEvent('Uploaded transcript during onboarding', {
            origin: 'Onboarding',
          });
          handleTranscriptUpload(dashboard.id, currentFile, {
            redirectAfterUpload: currentFiles.length === 1,
            template: template,
            callback: (fileId: string) => saveTask(dashboard.id, fileId, template),
          });
        });
      } else {
        const {
          data: { transcriptions },
        } = await client.query({
          query: GET_SAMPLE_TRANSCRIPT,
        });

        analytics.sendEvent('Onboarded with sample transcript', {
          origin: 'Onboarding',
        });

        const transcription = transcriptions.length ? transcriptions[0] : null;

        if (transcription) {
          const random = nanoid(4);
          const newFile = await createFile(dashboard.id, {
            name: transcription.file.name,
            type: transcription.file.type,
            s3VideoPath: transcription.file.s3VideoPath
              ? transcription.file.s3VideoPath + `?copy=${random}`
              : null,
            s3AudioPath: transcription.file.s3AudioPath
              ? transcription.file.s3AudioPath + `?copy=${random}`
              : null,
            status: transcription.file.status,
            folderId: null,
          });

          const doc = JSON.parse(transcriptions.length ? transcriptions[0].text : '{}');

          const newContent = template
            ? [
                {
                  type: 'summary',
                  content: [],
                  attrs: {
                    id: Math.floor(Math.random() * Date.now()).toString(16),
                    templateTitle: template.title,
                    templateId: template.id,
                    status: 'readyToStartGeneration',
                  },
                },
              ].concat(doc.content)
            : doc.content;

          const newTranscript = await createTranscript(dashboard.id, {
            fileId: newFile.id,
            name: transcription.name,
            text: JSON.stringify({
              ...doc,
              content: newContent,
            }),
            tagsTranscriptions: {
              create: transcription.tagsList?.map((tag: Tag) => ({ tagId: tag.id })) || [],
            },
            folderId: null,
          });

          if (template) {
            localStorage.setItem(
              `TASKS_${dashboard.id}_${newTranscript.id}`,
              JSON.stringify([
                {
                  id: 'summarize',
                  name: 'Summarize',
                  creditsPrice: 1,
                  description: 'Automatically summarize data using our library of AI templates.',
                  tagsEnabled: true,
                  summaryTemplate: template,
                },
              ])
            );
          }

          if (newTranscript) {
            history.push(`/projects/${dashboard.id}/data/${newTranscript.id}`);
            return;
          }
        }
      }

      if (!isPhone) {
        history.push(`/projects/${dashboard.id}/data`);
      } else {
        history.push(`/`);
      }
      return;
    }

    history.push('/');

    analytics.sendEvent('Finished onboarding', {
      origin: 'Onboarding',
    });
  };

  async function handleRegister() {
    try {
      const result = await registerMutation({
        variables: {
          input: {
            companyName: userInvited ? 'whatever' : companyName,
            fullName: fullName,
            slug: generateWorkspaceName(auth0user?.email),
            phone: phoneNumber,
          },
        },
      });

      if (result && result.data && result.data.register.success) {
        await loginWithRedirect();
        if (totalSteps === 1) {
          finishOnboarding(null, { skipAllSteps: true });
        } else {
          localStorage.setItem('onboardingStatus', 'step_1_complete');
        }
      } else {
        toast.error('Unable to register please try again.', {
          autoClose: 5000,
        });
      }
    } finally {
    }
  }

  const handleFirstStepContinue = () => {
    handleRegister();

    analytics.sendEvent('Register finished', {
      origin: 'Onboarding',
    });
  };

  const handleDataImportContinue = (files?: File[] | null, options?: { skip: boolean }) => {
    if (options?.skip) {
      analytics.sendEvent('Skipped file upload', {
        origin: 'Onboarding',
      });
      finishOnboarding(null, { withoutUploading: true });
      return;
    }

    setCurrentFiles(files || []);
    analytics.sendEvent('Selected files in onboarding', {
      origin: 'Onboarding',
    });
    setStepNumber(4);
  };

  if (error) {
    toast.error(error);
  }

  return (
    <Container>
      <LogoWrapper>
        <NotablyLogo />
      </LogoWrapper>
      <StepProgress totalSteps={totalSteps} currentStep={stepNumber} />

      <ContentContainer>
        <OnboardingWindowWrapper>
          {stepNumber === 0 && <CheckAccountStep onOnboardingContinue={() => setStepNumber(1)} />}
          {stepNumber === 1 && (
            <IntroductionStep
              loading={loading}
              totalSteps={totalSteps}
              userInvited={userInvited}
              fullName={fullName}
              companyName={companyName}
              setCompanyName={setCompanyName}
              setFullName={setFullName}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              onContinue={handleFirstStepContinue}
            />
          )}
          {stepNumber === 2 && (
            <InviteStep onContinue={async () => setStepNumber(3)} totalSteps={totalSteps} />
          )}
          {stepNumber === 3 && (
            <ImportDataStep onContinue={handleDataImportContinue} totalSteps={totalSteps} />
          )}
          {stepNumber === 4 && (
            <ChooseTemplateStep
              onContinue={(template) => finishOnboarding(template)}
              totalSteps={totalSteps}
            />
          )}
        </OnboardingWindowWrapper>
      </ContentContainer>
    </Container>
  );
}
