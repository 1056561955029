import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import NotesIcon from '../../Icons/NotesIcon';
import DataIcon from '../../Icons/DataIcon';
import InsightsIcon2 from '../../Icons/InsightsIcon2';
import InfoIcon from '../../Icons/InfoIcon';
import useBoards from '../../Hooks/useBoards';
import ProjectTitle from '../ProjectTitle';
import { Icon32 } from '../../Icons/Icon';
import { ChartPieIcon } from '@heroicons/react/outline';
import styled from 'styled-components';
import useMediaQuery from '../../Hooks/useMediaQuery';

import { Container, Link, LinkIcon, LinksContainer, TagsLink, TagsLinks } from './styles';

interface NavigationItem {
  name: string;
  href: string;
  icon: JSX.Element;
  secondaryLabel?: string;
}
const navigation: NavigationItem[] = [
  { name: 'Info', href: '/info', icon: <InfoIcon /> },
  { name: 'Data', href: '/data', icon: <DataIcon /> },
  {
    name: 'Tags',
    href: '/tags',
    icon: <Icon32.Tags />,
  },
  { name: 'Analysis', href: '/notes', icon: <NotesIcon /> },
  {
    name: 'Charts',
    href: '/analysis/charts',
    icon: <ChartPieIcon width={28} viewBox="0 0 33 32" style={{ marginLeft: 6, marginTop: 3 }} />,
  },
  {
    name: 'Insights',
    href: '/insights',
    icon: (
      <span className="pl-2 pr-1 my-[6px]">
        <InsightsIcon2 />
      </span>
    ),
  },
];

const Sidebar: React.FC = () => {
  const match = useRouteMatch({
    path: '/projects/:dashboardId',
    exact: false,
  });

  const matchTags = useRouteMatch({
    path: '/projects/:dashboardId/tags',
    exact: false,
  });

  const { dashboardId } = useParams<{ dashboardId: string }>();
  const { fetchBoard, updateBoard } = useBoards();
  const [loading, board] = fetchBoard(parseInt(dashboardId) ?? 0);
  const { isPhone } = useMediaQuery();

  return (
    <Container>
      {/* <div className="flex-1 flex flex-col"> */}
      {!isPhone && (
        <div className="px-2 py-2 font-medium">
          <ProjectTitle
            text={loading ? '' : board?.name}
            onSave={async (newName) => {
              await updateBoard(parseInt(dashboardId), {
                name: newName,
              });
            }}
          />
        </div>
      )}
      <LinksContainer>
        {navigation.map((item) => (
          <div key={item.name}>
            <Link
              key={item.name}
              activeClassName={item.href === '/tags' ? 'inactive' : 'active'}
              to={`${match?.url}${item.href}`}
            >
              <LinkIcon>{item.icon}</LinkIcon>

              {!isPhone && (
                <>
                  {item.name}
                  {item.secondaryLabel && <SecondaryLabel>{item.secondaryLabel}</SecondaryLabel>}
                </>
              )}
            </Link>
            {item.href === '/tags' && !isPhone && (
              <TagsLinks isOpen={!!matchTags}>
                <TagsLink to={`${match?.url}/tags/project`} activeClassName="current">
                  Project tags
                </TagsLink>
                <TagsLink to={`${match?.url}/tags/global`} activeClassName="current">
                  Global tags
                </TagsLink>
              </TagsLinks>
            )}
          </div>
        ))}
      </LinksContainer>
      {/* </div> */}
    </Container>
  );
};

const SecondaryLabel = styled.span`
  margin-left: 5px;
  display: flex;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  background: rgba(56, 33, 82, 0.2);
  color: #382152;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;

export default Sidebar;
