import React, { useEffect, useState } from 'react';

import { ExternalLinkIcon, CheckIcon } from '@heroicons/react/solid';
import Button from '../../Components/Button';
import { useBillingInfo, usePayments } from '../../Hooks/usePayments';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useOrganization } from '../../Hooks/useOrganization';
import { Loader } from '../../Components';
import SecondaryToolbar from '../../Components/SecondaryToolbar';
import { PlusIcon, MinusIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { tiersArray as tiers } from '../../Consts/tiers-08-23';
import { useTimeout } from 'usehooks-ts';
import ModalWindow from '../../Components/ModalWindow';
import {
  BottomLink,
  BottomLinkWrapper,
  Container,
  DecreaseSeatsButton,
  FeatureTitle,
  IconWrapper,
  IncreaseSeatsButton,
  ModalText,
  PlanCard,
  PlanCardFeature,
  PlanCardFeatures,
  PlanCardHeader,
  PlanDescription,
  PlanName,
  PlanPrice,
  PlanPriceContainer,
  PlansCards,
  PlansContainer,
  SeatsCount,
  TooltipWrapper,
  PlanCardActiveHeader,
  PlansHeader,
  Title,
  PlansWrapper,
  GrowSpace,
  RadioInputs,
  RadioInputContainer,
  RadioInput,
  RadioInputCheckmark,
  CustomQuoteText,
  FAQTitle,
  FAQQuestion,
  FAQLink,
  ContactUsText,
} from './styles';

import { PopupModal } from 'react-calendly';
import { useAuth0 } from '@auth0/auth0-react';
import useUsers from '../../Hooks/useUsers';
import { useIntercom } from 'react-use-intercom';
import TrialPlanPanel from '../../Components/TrialPlanPanel';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';

const hierarchy = ['free', 'pro', 'team', 'enterprise'];
const FEATURES = [
  { id: 'editors', name: 'Editors' },
  { id: 'viewers', name: 'Viewers' },
  { id: 'projects', name: 'Projects' },
  { id: 'aiCredits', name: 'AI Insight Credits' },
  { id: 'transcriptionHours', name: 'Transcription hours' },
  { id: 'suggestedTags', name: 'Suggested tags' },
  { id: 'customAiTemplates', name: 'Custom AI Templates' },
  { id: 'promptInstructions', name: 'Access to prompt instructions' },
  { id: 'globalTags', name: 'Global tags' },
  { id: 'globalSearch', name: 'Global search' },
  { id: 'participants', name: 'Participants Tracked' },
  { id: 'slack', name: 'Slack integration' },
  { id: 'multiLang', name: 'Multi-language support' },
  { id: 'sso', name: 'SSO & Encrypted Backups' },
  { id: 'accountManager', name: 'Dedicated account manager' },
  { id: 'securityReviews', name: 'Security Reviews' },
  { id: 'customContracts', name: 'Custom Contracts & Invoicing' },
];

const Plans = (): JSX.Element => {
  const { createCheckoutSession } = usePayments();
  const intercom = useIntercom();
  const { user } = useAuth0();
  const [isReduceModalOpened, setReduceModalOpened] = useState(false);
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);
  const history = useHistory();
  const [loading, organization, refetch] = useOrganization();
  const [billingTerms, setBillingTerms] = useState<'monthly' | 'yearly'>('yearly');
  const { fetchUsers } = useUsers();
  const [loadingUsers, users] = fetchUsers();
  const [billingInfoLoading, billingInfo] = useBillingInfo();

  const editors = users ? users.filter((user: any) => user.role !== 'viewer') : [];

  const teamTier = tiers.find((x) => x.id === 'team');
  const enterpriseTier = tiers.find((x) => x.id === 'enterprise');

  const [planSeats, setplanSeats] = useState<{ [key: string]: number }>({
    team: teamTier?.editors.default || 5,
    enterprise: enterpriseTier?.editors.default || 10,
  });

  const rootElt = document.getElementById('root');

  const url = new URL(window.location.href);

  const success = url.searchParams.get('success');
  const newPlanKey = url.searchParams.get('plan');

  const currentPlanInterval = organization.planInterval === 'year' ? 'yearly' : 'monthly';
  const currentTier = tiers.find((x) => x.id === organization.plan);
  const isTrial = organization.trialStatus === 'active';
  const isExpired = organization.trialStatus === 'expired';

  useEffect(() => {
    const editorsCount = users ? users.filter((user: any) => user.role !== 'viewer').length : 0;

    setplanSeats({
      team: Math.max(
        planSeats.team,
        Math.ceil(editorsCount / (teamTier?.editors.step || 1)) * (teamTier?.editors.step || 1),
        organization.prepaidSeats
      ),
      enterprise: Math.max(
        planSeats.team,
        Math.ceil(editorsCount / (enterpriseTier?.editors.step || 1)) *
          (enterpriseTier?.editors.step || 1),
        organization.prepaidSeats
      ),
    });
  }, [users]);

  useEffect(() => {
    setBillingTerms(!loading && organization.planInterval === 'month' ? 'monthly' : 'yearly');
  }, [organization, loading]);

  useEffect(() => {
    if (success) {
      const planTitle = tiers.find(
        (x) => x.lookupKey['monthly'] === newPlanKey || x.lookupKey['yearly'] === newPlanKey
      )?.name;

      toast.success(`All done! You have successfully subscribed to "${planTitle}" plan.`, {
        autoClose: 5000,
      });
    }
  }, []);

  useTimeout(async () => {
    if (success) {
      await refetch();
      history.push('/settings/plans');
    }
  }, 2000);

  async function handleClick(id: string, lookupKey: string, seats: number) {
    switch (id) {
      case 'enterprise':
        intercom.showNewMessage(
          'Hi, I want to learn more about upgrading my workspace to the Enterprise Plan.'
        );
        break;
      case 'free':
      case 'pro':
        if (editors.length > 1) {
          setReduceModalOpened(true);
          break;
        }
      default:
        const url = await createCheckoutSession(lookupKey, seats);

        if (url) {
          window.location.href = url;
        } else {
          toast.error('Something went wrong. Try one more time.');
        }
    }
  }

  if (loading || success) {
    return <Loader />;
  }

  const planIndex = hierarchy.indexOf(organization.plan);

  function getButtonText(planId: string, isCurrent: boolean) {
    if (isTrial) return 'Upgrade now';
    if (isCurrent && !isTrial) return 'Current Plan';
    if (planId === 'enterprise') return 'Contact Us';

    if (
      hierarchy.indexOf(planId) > planIndex ||
      hierarchy.indexOf(planId) === planIndex ||
      (isCurrent && isTrial)
    ) {
      return 'Upgrade now';
    }

    return 'Downgrade';
  }

  const getUpgradeButtonType = (tierId: string, isCurrent: boolean) => {
    if (isCurrent && isTrial) return 'primaryLight';
    if (isTrial) return 'secondary';
    if (isExpired && tierId === 'team') return 'primary';
    if (isExpired) return 'secondary';
    return hierarchy.indexOf(tierId) <= planIndex ? 'secondary' : 'primary';
  };

  return (
    <div className="bg-white mb-20">
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          <h1 className={'text-l font-medium mt-1'}>Settings / Plans</h1>
        </div>
      </SecondaryToolbar>

      {(isTrial || isExpired) && (
        <div className="flex items-center justify-center mt-4 mb-10">
          <TrialPlanPanel endDate={moment(organization.trialExpireAt)} />
        </div>
      )}

      {/* {!isTrial && !isExpired && (
        <TooltipWrapper>
          <div className="bg-tooltip-purple flex flex-row p-3 rounded-lg my-5 max-w-[1000px]">
            <div>
              <InformationCircleIcon className={'w-5 h-5'} />
            </div>
            <div className="mx-2 font-medium">
              {organization?.plan?.includes('free') &&
                `You’re on the free plan. Upgrade to a paid plan for more viewer seats and to add
            additional editors to your workspace.`}
              {!organization?.plan?.includes('free') && (
                <>
                  Your team is on the {capitalize(organization.plan)} plan for $
                  {currentTier?.price[currentPlanInterval]}/month per editor. There are{' '}
                  {editors.length} editors in this workspace.
                  {!billingInfoLoading &&
                    billingInfo.upcomingInvoice &&
                    ` Your next payment of $${
                      currency(billingInfo.upcomingInvoice.amount, { fromCents: true }).value
                    } will be on ${moment
                      .unix(billingInfo.upcomingInvoice.created)
                      .format('MMMM Do, YYYY')}.`}
                </>
              )}
            </div>
            {!organization?.plan?.includes('free') && (
              <div className="flex-none">
                <Button type="secondary" onClick={() => history.push('/settings/billing')}>
                  View Billing
                </Button>
              </div>
            )}
          </div>
        </TooltipWrapper>
      )} */}

      <Container>
        <PlansHeader>
          <Title>All plans</Title>
          <RadioInputs
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const currentValue = e.target.value;
              if (currentValue !== 'yearly' && currentValue !== 'monthly') return;
              setBillingTerms(e.target.value as 'yearly' | 'monthly');
            }}
          >
            <RadioInputContainer>
              Billed monthly
              <RadioInput
                type="radio"
                value="monthly"
                name="planInterval"
                checked={billingTerms !== 'yearly'}
              />
              <RadioInputCheckmark />
            </RadioInputContainer>

            <RadioInputContainer>
              Billed yearly
              <RadioInput
                type="radio"
                value="yearly"
                name="planInterval"
                checked={billingTerms === 'yearly'}
              />
              <RadioInputCheckmark />
            </RadioInputContainer>
          </RadioInputs>
        </PlansHeader>
        <PlansWrapper>
          <PlansContainer>
            <PlansCards>
              <PlanCard>
                <GrowSpace />
                {FEATURES.map((feature) => (
                  <FeatureTitle key={feature.id}>{feature.name}</FeatureTitle>
                ))}
              </PlanCard>
              {tiers.map((tier) => {
                const isCurrent =
                  tier.id === organization.plan &&
                  (billingTerms === currentPlanInterval || organization.plan === 'free') &&
                  !organization.onLegacyPlan;
                return (
                  <PlanCard key={tier.id} active={isCurrent} isTrial={isTrial}>
                    {isCurrent && (
                      <PlanCardActiveHeader isTrial={isTrial}>Current plan</PlanCardActiveHeader>
                    )}
                    <PlanCardHeader>
                      <PlanName>{tier.name}</PlanName>
                      {tier.id === 'enterprise' ? (
                        <CustomQuoteText>Custom quote</CustomQuoteText>
                      ) : (
                        <PlanPriceContainer>
                          <div>
                            <PlanPrice>
                              ${tier.price[billingTerms] * (planSeats[tier.id] || 1)}
                            </PlanPrice>{' '}
                            / mo
                          </div>
                        </PlanPriceContainer>
                      )}
                      <PlanDescription>{tier.description}</PlanDescription>
                      {((isTrial && tier.id !== 'free') || !isTrial) && (
                        <Button
                          type={getUpgradeButtonType(tier.id, isCurrent)}
                          disabled={isCurrent && !isTrial}
                          onClick={() =>
                            handleClick(
                              tier.id,
                              tier.lookupKey[billingTerms],
                              planSeats[tier.id] || 1
                            )
                          }
                        >
                          {getButtonText(tier.id, isCurrent)}
                        </Button>
                      )}
                    </PlanCardHeader>
                    <PlanCardFeatures>
                      {FEATURES.map((feature) => {
                        if (feature.id === 'editors') {
                          return tier.editors.isAdjustable ? (
                            <PlanCardFeature key={feature.id}>
                              <DecreaseSeatsButton
                                onClick={(e) => {
                                  const newCount = planSeats[tier.id] - tier.editors.step;
                                  if (newCount >= tier.editors.min && newCount >= editors.length)
                                    setplanSeats({ ...planSeats, [tier.id]: newCount });
                                }}
                              >
                                <MinusIcon className={'w-4 h-4'} />
                              </DecreaseSeatsButton>
                              <SeatsCount>{planSeats[tier.id]}</SeatsCount>
                              <IncreaseSeatsButton
                                onClick={(e) => {
                                  const newCount = planSeats[tier.id] + tier.editors.step;
                                  if (newCount <= tier.editors.max)
                                    setplanSeats({ ...planSeats, [tier.id]: newCount });
                                }}
                              >
                                <PlusIcon className={'w-4 h-4'} />
                              </IncreaseSeatsButton>
                            </PlanCardFeature>
                          ) : (
                            <PlanCardFeature key={feature.id}>
                              {tier.editors.default}
                              {tier.id === 'enterprise' ? '+' : ''}
                            </PlanCardFeature>
                          );
                        }
                        const value = tier.features.get(feature.id);
                        const isBoolean = typeof value === 'boolean';
                        if (isBoolean && value) {
                          return (
                            <PlanCardFeature key={feature.id}>
                              <IconWrapper>
                                <CheckIcon aria-hidden="true" />
                              </IconWrapper>
                            </PlanCardFeature>
                          );
                        }
                        return <PlanCardFeature key={feature.id}>{value}</PlanCardFeature>;
                      })}
                    </PlanCardFeatures>
                  </PlanCard>
                );
              })}
            </PlansCards>
          </PlansContainer>
        </PlansWrapper>
      </Container>
      <BottomLinkWrapper>
        <BottomLink target="_blank" rel="noreferrer" href="https://www.notably.ai/pricing">
          <IconWrapper mr="8">
            <ExternalLinkIcon aria-hidden="true" />
          </IconWrapper>
          Compare all plans
        </BottomLink>
      </BottomLinkWrapper>

      <Container>
        <FAQTitle>Pricing FAQ</FAQTitle>
        <Accordion defaultIndex={[0]} allowMultiple borderColor="#d7d3dc">
          <AccordionItem>
            <AccordionButton
              _focus={{ boxShadow: 'none' }}
              _hover={{ bg: 'rgba(133, 95, 168, 0.1)' }}
            >
              <Box flex="1" textAlign="left">
                <FAQQuestion>What are AI credits?</FAQQuestion>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} fontSize={14} fontWeight={500}>
              AI credits are used to generate summaries and insights. Summaries are generated on
              data files, such as recordings, transcripts, or notes. Insights are generated from
              highlights across all of your data files either from themes, tags, or a combination of
              tags.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              _focus={{ boxShadow: 'none' }}
              _hover={{ bg: 'rgba(133, 95, 168, 0.1)' }}
            >
              <Box flex="1" textAlign="left">
                <FAQQuestion>What languages does Notably support?</FAQQuestion>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} fontSize={14} fontWeight={500}>
              Even on the free plan you can transcribe audio or video in 30+ languages and accents.{' '}
              <FAQLink
                href="https://help.notably.ai/en/articles/6909600-which-languages-are-supported-in-notably"
                target="_blank"
                rel="noreferrer"
              >
                Here is a list of the languages we support.
              </FAQLink>{' '}
              Generating summaries and insights in multiple languages is supported on the enterprise
              plan.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              _focus={{ boxShadow: 'none' }}
              _hover={{ bg: 'rgba(133, 95, 168, 0.1)' }}
            >
              <Box flex="1" textAlign="left">
                <FAQQuestion>Does Notably offer discounts?</FAQQuestion>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} fontSize={14} fontWeight={500}>
              Yes. We are passionate about supporting the future generation of researchers and
              providing access to tooling for charitable organizations. If you are a student,
              teacher, or non-profit, use the code EDU20 to save 20% off any plan. If you’d like to
              learn more about Notably hosting a lecture or workshop at your institution, please
              reach out to Allison@notably.ai.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              _focus={{ boxShadow: 'none' }}
              _hover={{ bg: 'rgba(133, 95, 168, 0.1)' }}
            >
              <Box flex="1" textAlign="left">
                <FAQQuestion>What if I need more time on the free trial or a demo?</FAQQuestion>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} fontSize={14} fontWeight={500}>
              If you need more time on the trial,{' '}
              <ContactUsText
                onClick={() =>
                  intercom.showNewMessage(
                    'Hi, I’d like to request more time on the free trial. Can you help me out?'
                  )
                }
              >
                just let us know.
              </ContactUsText>{' '}
              We’re happy to extend the trial and you can schedule a demo with us anytime{' '}
              <FAQLink
                href="https://calendly.com/notably-sales/notably-onboarding"
                target="_blank"
                rel="noreferrer"
              >
                here.
              </FAQLink>{' '}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>

      {rootElt && (
        <PopupModal
          url="https://calendly.com/notably-sales/notably-onboarding"
          pageSettings={{
            hideEventTypeDetails: true,
            hideGdprBanner: true,
            textColor: '382152',
            primaryColor: '3b2651',
          }}
          prefill={{
            email: user?.email || '',
            name: user?.name || '',
            customAnswers: { a1: organization.name || '' },
          }}
          onModalClose={() => setIsCalendlyModalOpen(false)}
          open={isCalendlyModalOpen}
          rootElement={rootElt}
        />
      )}

      {isReduceModalOpened && (
        <ModalWindow
          headerText="Change your plan"
          confirmText="Manage team roles"
          cancelText="Contact us"
          onConfirm={async () => {
            history.push('/settings/users');
            setReduceModalOpened(false);
          }}
          onClose={() => setReduceModalOpened(false)}
          onCancel={() => {
            intercom.showNewMessage(
              'Hi, I am having trouble changing my plan. Please help me out. '
            );
          }}
        >
          <ModalText>
            To reduce the number of licenses you have, you need to remove or downgrade{' '}
            {editors.length - 1} editors to viewers.
          </ModalText>
        </ModalWindow>
      )}
    </div>
  );
};

export default Plans;
