import React, { FC, useContext, useRef, useState } from 'react';
import languageOptions from '../../Consts/languageOptions';
import { Icon16, Icon24, Icon48 } from '../../Icons/Icon';
import filesIllustration from '../../assets/illustrations/files.png';
import { CloudFile } from '../../Models';
import Button from '../Button';
import GoogleDrivePicker from '../GoogleDrivePicker';
import Select from '../Select';

import {
  UploadedContent,
  FileItems,
  UploadSettings,
  FileItemDivider,
  SelectWrapper,
  SelectTitle,
  ButtonWrapper,
  ButtonUploadingState,
  ButtonUploadingStateText,
  UploadArea,
  Illustration,
  Title,
  Spinner,
  TextDivider,
  ZoomButton,
  ZoomButtonImage,
  ZoomButtonText,
  GoogleButton,
  Subtitle,
  FAQLink,
  UploadIcon,
  Buttons,
  UploadButton,
  SecondaryLabel,
} from './styles';

import FileCard from './FileCard';
import { UploadFilesContext } from '../../Context/UploadFilesContext';
import { OptionTypeBase } from 'react-select';
import OneDrivePickerButton from '../OneDrive/OneDrivePickerButton';

interface FileUploaderProps {
  currentFiles: File[] | null;
  currentCloudFiles?: CloudFile[];
  currentLanguage?: OptionTypeBase;
  zoomEnabled?: boolean;
  googleDriveEnabled?: boolean;
  oneDriveEnabled?: boolean;
  hideFaq?: boolean;
  isGoogleDriveUploading?: boolean;
  skipCardsStep?: boolean;
  onZoomButtonClick?: () => void;
  onOneDriveButtonClick?: () => void;
  onAccept?: () => void;
  onCurrentFilesChange: (files: File[] | null) => void;
  onCurrentCloudFilesChange?: (files: CloudFile[]) => void;
  onCurrentLanguageChange?: (language: OptionTypeBase) => void;
  onCancelFile: (fileName?: string) => void;
  onCancelCloudFile?: (fileName?: string) => void;
}

const availableExtensions = ['mp4', 'm4a', 'mp3', 'srt', 'vtt', 'txt', 'docx', 'pdf'];

const FileUploader: FC<FileUploaderProps> = ({
  currentFiles,
  currentCloudFiles,
  currentLanguage,
  zoomEnabled,
  googleDriveEnabled,
  oneDriveEnabled,
  hideFaq,
  isGoogleDriveUploading,
  skipCardsStep,
  onCurrentFilesChange,
  onCurrentCloudFilesChange,
  onCurrentLanguageChange,
  onAccept,
  onZoomButtonClick,
  onOneDriveButtonClick,
  onCancelFile,
  onCancelCloudFile,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const { uploadingFiles, addUploadingFile } = useContext(UploadFilesContext);

  const filesSelected = (currentFiles?.length || 0) + (currentCloudFiles?.length || 0);

  const getFileWithStatus = (fileName: string) => {
    return uploadingFiles.find((item) => item.file.name === fileName) || { uploadingProgress: 0 };
  };

  const allFilesUploading = () => {
    return !!(
      uploadingFiles?.length &&
      uploadingFiles?.every((currentUploading) => currentUploading.isUploading)
    );
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const files = Array.from(e.target.files);
      files.forEach((file) =>
        addUploadingFile({
          file,
          isUploading: false,
          uploadingProgress: 0,
        })
      );

      onCurrentFilesChange(files);
      if (skipCardsStep) {
        onAccept && onAccept();
      }
    }
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      const files = Array.from(e.dataTransfer.files).filter((file) =>
        availableExtensions.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase()))
      );
      files.forEach((file) => {
        if (
          !availableExtensions.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
        ) {
          return;
        }

        addUploadingFile({
          file,
          isUploading: false,
          uploadingProgress: 0,
        });
      });

      onCurrentFilesChange(files);
      if (skipCardsStep) {
        onAccept && onAccept();
      }
    }
  };

  const handleBrowseFilesClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      {!!filesSelected ? (
        <UploadedContent>
          <FileItems>
            {currentFiles?.map((currentFile) => (
              <FileCard
                key={currentFile.name}
                fileType={currentFile.type}
                size={currentFile.size}
                name={currentFile.name}
                progress={getFileWithStatus(currentFile.name).uploadingProgress}
                onDelete={(filename) => onCancelFile(filename)}
              />
            ))}
            {currentCloudFiles &&
              currentCloudFiles.map((currentFile) => (
                <FileCard
                  key={currentFile.sourceId}
                  size={currentFile.size}
                  name={currentFile.name}
                  progress={0}
                  onDelete={(filename) => onCancelCloudFile && onCancelCloudFile(filename)}
                />
              ))}
          </FileItems>

          {!!currentLanguage && (
            <UploadSettings>
              <FileItemDivider />

              <SelectWrapper>
                <SelectTitle>Language</SelectTitle>
                <Select
                  options={languageOptions}
                  onChange={(option) => {
                    if (!option) return;
                    onCurrentLanguageChange && onCurrentLanguageChange(option);
                  }}
                  value={currentLanguage}
                  isSearchable={true}
                  elementWithDividerNumber={2}
                />
              </SelectWrapper>

              <Buttons>
                <ButtonWrapper>
                  <Button type="secondary" onClick={() => onCancelFile()}>
                    Cancel
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    type="primary"
                    onClick={() => onAccept && onAccept()}
                    disabled={allFilesUploading() || isGoogleDriveUploading}
                  >
                    {allFilesUploading() || isGoogleDriveUploading ? (
                      <ButtonUploadingState>
                        <Spinner>
                          <Icon16.SpinnerCircle />
                        </Spinner>
                        <ButtonUploadingStateText>{`Uploading file${
                          filesSelected > 1 ? 's' : ''
                        }...`}</ButtonUploadingStateText>
                      </ButtonUploadingState>
                    ) : (
                      'Next, choose a template'
                    )}
                  </Button>
                </ButtonWrapper>
              </Buttons>
            </UploadSettings>
          )}
        </UploadedContent>
      ) : (
        <UploadArea
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          dragActive={dragActive}
        >
          <Illustration src={filesIllustration} />
          <Title>Drag and drop or browse to choose file(s).</Title>

          <UploadButton onClick={handleBrowseFilesClick}>Choose File(s)</UploadButton>

          <>
            {(zoomEnabled || googleDriveEnabled) && <TextDivider>or</TextDivider>}
            {zoomEnabled && (
              <ZoomButton onClick={onZoomButtonClick}>
                <ZoomButtonImage>
                  <Icon24.ZoomIcon />
                </ZoomButtonImage>
                <ZoomButtonText>Import from Zoom</ZoomButtonText>
              </ZoomButton>
            )}

            {googleDriveEnabled && (
              <GoogleDrivePicker
                onFileSelected={(files: CloudFile[]) => {
                  onCurrentCloudFilesChange && onCurrentCloudFilesChange(files);

                  if (skipCardsStep) {
                    onAccept && onAccept();
                  }
                }}
              >
                <GoogleButton>
                  <ZoomButtonImage>
                    <Icon24.GoogleDriveIcon />
                  </ZoomButtonImage>
                  <ZoomButtonText>Import from Google</ZoomButtonText>
                </GoogleButton>
              </GoogleDrivePicker>
            )}

            {oneDriveEnabled && (
              <OneDrivePickerButton
                onFileSelected={(files) => {
                  onCurrentCloudFilesChange && files && onCurrentCloudFilesChange(files);
                }}
              />
            )}
          </>

          <Subtitle>
            Video (.mp4, .mov, .m4a ) Audio (.mp3) Docs (.txt, .docx, .pdf{' '}
            <SecondaryLabel>Beta</SecondaryLabel>) Transcript (.srt, .vtt)
          </Subtitle>
          {!hideFaq && (
            <Subtitle>
              <FAQLink
                href="https://help.notably.ai/en/articles/6909600-which-languages-are-supported-in-notably"
                target="_blank"
              >
                Notably transcribes in 20+ languages. Learn more.
              </FAQLink>
            </Subtitle>
          )}
        </UploadArea>
      )}
      <input
        id={'video_upload'}
        name={'video_upload'}
        accept={
          'video/mp4, video/quicktime, audio/mp3, audio/mp4, audio/x-m4a, .srt, .vtt, .txt, .docx, .pdf'
        }
        type={'file'}
        onChange={handleFileInputChange}
        className={'hidden'}
        ref={inputRef}
        multiple
      />
      <UploadIcon show={dragActive}>
        <Icon48.Upload />
      </UploadIcon>
    </>
  );
};

export default FileUploader;
