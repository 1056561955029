import styled, { css } from 'styled-components';

export const Container = styled.div<{ withToolbarMargin?: boolean }>`
  position: sticky;
  top: ${({ withToolbarMargin }) => (withToolbarMargin ? '107px' : 0)};
  z-index: 3;
  width: 100%;
  padding: 4px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dddddd;
  background-color: #ffffff;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RestButtonsConainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const Button = styled.button<{ isActive?: boolean; disabled?: boolean; isBright?: boolean }>`
  display: flex;
  align-items: center;
  outline: none;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  transition: background-color 0.2s ease;

  ${({ isBright }) =>
    isBright &&
    `
  background: linear-gradient(180deg, #e7dbfb 0%, #e4e8fe 100%);
  `}

  &:last-child {
    margin: 0;
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: rgba(59, 38, 81, 0.4);
  `}

  ${({ isActive }) =>
    isActive &&
    `
    background-color: rgba(133, 95, 168, 0.1);
  `}
`;

export const GenerateSummaryButton = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-right: 8px;
  background: linear-gradient(180deg, #e7dbfb 0%, #e4e8fe 100%);
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
`;

export const CleanButtonContainer = styled.div`
  position: relative;
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 5px 8px 5px 10px;
  margin-right: 5px;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 5px;
  flex-shrink: 0;
`;

export const ButtonDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #dddddd;
  margin: 0 5px 0 10px;
`;

export const DropdownButton = styled.div``;

export const DropdownList = styled.div`
  background-color: #ffffff;
  border: 1px solid #d7d3dc;
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 290px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
`;

export const DropdownItemIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 10px;
  background-color: #efedf1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DropdownItemTitle = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const DropdownItemSubtitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: rgba(59, 38, 81, 0.6);
`;

export const DropdownDivider = styled.div`
  width: 95%;
  height: 1px;
  background-color: #d7d3dc;
  margin: 5px auto;
`;

export const DropdownItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(133, 95, 168, 0.1);
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: rgba(59, 38, 81, 0.4);
    cursor: default;

    &:hover {
      background-color: transparent;
    }

    & ${DropdownItemSubtitle} {
      color: rgba(59, 38, 81, 0.4);
    }
  `}
`;

export const ToolbarButton = styled.div`
  position: relative;
`;

export const ToolbarButtonIcon = styled.div`
  color: rgba(177, 168, 185, 1);
  margin-right: 5px;
`;

export const ToolbarButtonText = styled.div<{ posty?: boolean }>`
  min-width: 100px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  ${({ posty }) =>
    posty &&
    css`
      background: linear-gradient(90deg, #8e84ee 0%, #351a8a 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}
  margin-right: 10px;
`;

export const ToolbarDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: rgba(215, 211, 220, 1);
  margin: 0 10px;
`;
