import { Editor } from '@tiptap/react';
import { TSpeakerBlock } from '../../../../Context/SpeakersContext';

export const generateNewSpeakers = (
  editor: Editor,
  setSpeakersNamesRef: React.MutableRefObject<(speakerNames: string[]) => void>,
  setSpeakerBlocksRef: React.MutableRefObject<(speakerBlocks: TSpeakerBlock[]) => void>
): void => {
  const newSpeakers: TSpeakerBlock[] = [];

  editor.state.doc.content.nodesBetween(0, editor.state.doc.content.size, (node) => {
    if (node.type.name === 'speaker') {
      newSpeakers.push({ id: node.attrs.id, speakerName: node.attrs.speakerName });
    }
  });
  setSpeakersNamesRef.current(newSpeakers.map((speaker) => speaker.speakerName));
  setSpeakerBlocksRef.current(newSpeakers);
};
