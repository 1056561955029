import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCreatePrompt } from '../../Hooks/usePrompts';
import { Icon24 } from '../../Icons/Icon';
import Spinner from '../Spinner';
import {
  Container,
  CtaButton,
  CtaTitle,
  LearnMoreContainer,
  LearnMoreTitle,
  LearnMoreText,
  LearnMoreGuarantee,
  LearnMoreButton,
  LearnMoreIcon,
  SpinnerWrapper,
} from './styles';

interface CustomTemplateCtaProps {
  customButtonText?: string;
}

const CustomTemplateCta: FC<CustomTemplateCtaProps> = ({ customButtonText }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [createPrompt] = useCreatePrompt();

  const handleCreatePrompt = async () => {
    setIsCreating(true);
    const newPrompt = await createPrompt({
      title: 'New template',
      description: '',
      isEnabled: false,
      mainPrompt: '',
      type: 'insight',
      outputLength: 'short',
    });
    setIsCreating(false);
    window.open(`/templates/${newPrompt.id}`, '_blank');
  };

  return (
    <Container>
      <CtaButton onClick={handleCreatePrompt}>
        <CtaTitle>Add a custom AI template to your workspace</CtaTitle>
      </CtaButton>

      <LearnMoreContainer>
        <LearnMoreTitle>
          <LearnMoreIcon>
            <Icon24.ChevronBadge />
          </LearnMoreIcon>
          Add-on
        </LearnMoreTitle>
        <LearnMoreText>
          Create a one of a kind AI template to instantly capture things you care about in a format
          that’s customized for you.
        </LearnMoreText>
        <LearnMoreGuarantee>Guaranteed or your money back</LearnMoreGuarantee>
        {isCreating ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <LearnMoreButton onClick={handleCreatePrompt}>
            {customButtonText || 'Create template'}
          </LearnMoreButton>
        )}
      </LearnMoreContainer>
    </Container>
  );
};

export default CustomTemplateCta;
