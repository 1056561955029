/* eslint-disable @typescript-eslint/no-empty-function */
import { CancelTokenSource } from 'axios';
import React, { FC, useContext, useState } from 'react';
import { ProjectChecklistContext } from './ProjectChecklistContext';

type FileWithStatus = {
  file: File;
  isUploading: boolean;
  uploadingProgress: number;
  requestCancelSource?: CancelTokenSource;
};

interface UploadFilesContextProps {
  uploadingFiles: FileWithStatus[];
  filesUploadModalOpen: boolean;
  addUploadingFile(file: FileWithStatus): void;
  setFileUploadingStatus(fileName: string, uploadingStatus: boolean): void;
  removeUploadingFile(fileName: string): void;
  removeAllUploadingFiles(): void;
  setFilesUploadingModalOpen(isOpen: boolean): void;
  setRequestCancelSource(fileName: string, requestCancelSource: CancelTokenSource): void;
  updateFileProgress(fileName: string, progress: number): void;
  uploadFile(
    dashboardId: string,
    file: File,
    uploadHandler: (dashboardId: string, file: File, options: any) => Promise<void>,
    options: {
      language?: string;
      aiTemplate?: string;
      speakerCount?: number;
      callback?(fileId: string): void;
    },
    onUpload?: (file: File) => void
  ): void;
  cancelUploading(fileName: string): void;
}

export const UploadFilesContext = React.createContext<UploadFilesContextProps>({
  uploadingFiles: [],
  filesUploadModalOpen: false,
  setFilesUploadingModalOpen: () => {},
  addUploadingFile: () => {},
  removeUploadingFile: () => {},
  removeAllUploadingFiles: () => {},
  setFileUploadingStatus: () => {},
  uploadFile: () => {},
  updateFileProgress: () => {},
  setRequestCancelSource: () => {},
  cancelUploading: () => {},
});

export const UploadFilesContextProvider: FC = ({ children }) => {
  const [filesUploadModalOpen, setFilesUploadingModalOpen] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState<FileWithStatus[]>([]);
  const { markSummaryAdded } = useContext(ProjectChecklistContext);

  const addUploadingFile = (file: FileWithStatus) => {
    setUploadingFiles((uploadingFiles) => [...uploadingFiles, file]);
  };

  const removeUploadingFile = (fileName: string) => {
    setUploadingFiles((currentFiles) => currentFiles.filter((item) => item.file.name !== fileName));
  };

  const removeAllUploadingFiles = () => {
    setUploadingFiles([]);
  };

  const updateFileProgress = (fileName: string, progress: number) => {
    setUploadingFiles((currentState) => {
      const newState = currentState.slice();
      const fileIndex = newState.findIndex((item) => item.file.name === fileName);

      if (fileIndex === -1) return currentState;

      newState[fileIndex].uploadingProgress = progress;
      return newState;
    });
  };

  const setFileUploadingStatus = (fileName: string, uploadingStatus: boolean) => {
    const newState = uploadingFiles.slice();
    const fileIndex = newState.findIndex((item) => item.file.name === fileName);
    if (fileIndex === -1) return;
    newState[fileIndex].isUploading = uploadingStatus;
    setUploadingFiles(newState);
  };

  const setRequestCancelSource = (fileName: string, requestCancelSource: CancelTokenSource) => {
    const newState = uploadingFiles.slice();
    const fileIndex = newState.findIndex((item) => item.file.name === fileName);
    if (fileIndex === -1) return;
    newState[fileIndex].requestCancelSource = requestCancelSource;
  };

  const uploadFile = async (
    dashboardId: string,
    file: File,
    uploadHandler: (dashboardId: string, file: File, options: any) => Promise<void>,
    options: {
      language?: string;
      aiTemplate?: string;
      speakerCount?: number;
      callback?(fileId: string): void;
    },
    onUpload?: (file: File) => void
  ) => {
    const { language, aiTemplate, speakerCount, callback } = options;
    setFileUploadingStatus(file.name, true);

    await uploadHandler(dashboardId, file, {
      onProgressChange: (progress: number) => {
        updateFileProgress(file.name, progress);
      },
      language,
      aiTemplate,
      speakerCount,
      callback: callback,
    });

    if (aiTemplate) {
      markSummaryAdded();
    }

    removeUploadingFile(file.name);
    onUpload && onUpload(file);
  };

  const cancelUploading = (fileName: string) => {
    const currentFile = uploadingFiles.find((item) => item.file.name === fileName);
    if (!currentFile) return;

    currentFile.requestCancelSource && currentFile.requestCancelSource.cancel();
    removeUploadingFile(fileName);
  };

  return (
    <UploadFilesContext.Provider
      value={{
        uploadingFiles,
        filesUploadModalOpen,
        addUploadingFile,
        removeUploadingFile,
        removeAllUploadingFiles,
        setFileUploadingStatus,
        setFilesUploadingModalOpen,
        uploadFile,
        updateFileProgress,
        setRequestCancelSource,
        cancelUploading,
      }}
    >
      {children}
    </UploadFilesContext.Provider>
  );
};
