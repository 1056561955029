import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  AUTHORIZE_SLACK,
  AUTHORIZE_ZOOM,
  AUTHORIZE_GOOGLE,
  AUTHORIZE_MIRO,
} from '../GraphQL/mutations';
import { useHistory } from 'react-router';
import { Loader } from '../Components';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import { TableSimple } from '../Components/TableSimple';
import { useAuth0 } from '@auth0/auth0-react';
import {
  GoogleClientId,
  REDIRECT_URI,
  GOOGLE_REDIRECT_URI,
} from '../Components/Integrations/utils/constants';
import IntegrationInfo from '../Components/Integrations/IntegrationInfo/IntegrationInfo';
import { useFetchSlackWebhooks } from '../Hooks/useSlackWebhooks';
import useFeatureFlags, {
  featureNameSlack,
  featureNameGoogle,
  featureNameOneDrive,
  featureNameMiro,
} from '../Hooks/useFeatureFlags';
import { slackOAuthRedirectURI } from '../Utils/slackUtils';
import { useFetchIntegrations } from '../Hooks/useFetchIntegrations';
import OneDriveIntegrationInfo from '../Components/Integrations/IntegrationInfo/OneDriveIntegrationInfo';

declare const google: any;

const MIRO_CLIENT_ID = (import.meta.env.VITE_MIRO_CLIENT_ID || '3458764572617899541') as string;

export const MIRO_REDIRECT_URI = `${window.location.origin}/settings/integrations?notably-integration=miro`;

export default function Integrations(): JSX.Element {
  const [authorizeZoomMutation] = useMutation(AUTHORIZE_ZOOM);
  const [authorizeGoogleMutation] = useMutation(AUTHORIZE_GOOGLE);
  const [authorizeSlackMutation] = useMutation(AUTHORIZE_SLACK);
  const [authorizeMiroMutation] = useMutation(AUTHORIZE_MIRO);
  const { data, loading, refetch } = useFetchIntegrations();
  const slackWebhookQuery = useFetchSlackWebhooks();
  const { user } = useAuth0();
  const history = useHistory();

  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  const integrationCallbackType = url.searchParams.get('notably-integration');

  const { isFeatureEnabled } = useFeatureFlags();
  const slackFeatureEnabled = isFeatureEnabled(featureNameSlack);
  const oneDriveFeatureEnabled = isFeatureEnabled(featureNameOneDrive);
  const googleFeatureEnabled = isFeatureEnabled(featureNameGoogle);
  const miroFeatureEnabled = isFeatureEnabled(featureNameMiro);

  useEffect(() => {
    if (!code) return;

    switch (integrationCallbackType) {
      case 'miro':
        authorizeMiroMutation({ variables: { code, redirectUri: MIRO_REDIRECT_URI } })
          .then(refetch)
          .finally(() => history.replace('/settings/integrations'));
        break;
      case 'slack':
        authorizeSlackMutation({ variables: { code, redirectUri: slackOAuthRedirectURI } })
          .then(refetch)
          .then(slackWebhookQuery.refetch)
          .finally(() => history.replace('/settings/integrations/Slack'));
        break;
      default:
        authorizeZoomMutation({ variables: { code, redirectUri: REDIRECT_URI } })
          .then(refetch)
          .finally(() => history.replace('/settings/integrations'));
    }
  }, [code]);

  if (loading || slackWebhookQuery.loading || code) {
    return <Loader />;
  }

  const zoomConnected = data?.integrations?.find(
    (x) => x.type === 'zoom' && x.createdBy === user?.['https://notably.ai/claims/user_id']
  );

  const oneDriveConnected = data?.integrations?.find(
    (x) => x.type === 'onedrive' && x.createdBy === user?.['https://notably.ai/claims/user_id']
  );

  const googleConnected = data?.integrations?.find(
    (x) => x.type === 'google' && x.createdBy === user?.['https://notably.ai/claims/user_id']
  );

  const miroConnected = data?.integrations?.find(
    (x) => x.type === 'miro' && x.createdBy === user?.['https://notably.ai/claims/user_id']
  );

  const slackConnected = data?.integrations?.find((int) => int.type === 'slack');

  return (
    <>
      <SecondaryToolbar>
        <div className="flex w-full flex-row py-3 px-4 justify-between">
          Settings / Integrations
        </div>
      </SecondaryToolbar>
      <div className="flex h-full justify-center pt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <TableSimple>
          <>
            {miroFeatureEnabled && (
              <>
                <h2 className={'text-l font-medium my-2 mt-4'}>Whiteboard tools</h2>
                <IntegrationInfo
                  connection={miroConnected}
                  name="Miro"
                  onConnect={async () => {
                    const url = `https://miro.com/oauth/authorize?response_type=code&client_id=${MIRO_CLIENT_ID}&redirect_uri=${MIRO_REDIRECT_URI}`;
                    window.location.href = url;
                  }}
                />
              </>
            )}
            {slackFeatureEnabled && (
              <>
                <h2 className={'text-l font-medium my-2 mt-4'}>Communication tools</h2>
                <IntegrationInfo connection={slackConnected} name="Slack" />
              </>
            )}

            <h2 className={'text-l font-medium my-2 mt-4'}>Video conferencing tools</h2>
            <IntegrationInfo connection={zoomConnected} name="Zoom" />

            {googleFeatureEnabled && (
              <>
                <br />
                <IntegrationInfo
                  connection={googleConnected}
                  name="Google"
                  onConnect={async () => {
                    const client = google.accounts.oauth2.initCodeClient({
                      client_id: GoogleClientId,
                      scope:
                        'https://www.googleapis.com/auth/userinfo.email \
                        https://www.googleapis.com/auth/drive.readonly',
                      ux_mode: 'popup',
                      callback: async (response: any) => {
                        await authorizeGoogleMutation({
                          variables: { code: response.code, redirectUri: GOOGLE_REDIRECT_URI },
                        });
                        await refetch();
                        history.replace('/settings/integrations/google');
                      },
                    });
                    client.requestCode();
                  }}
                />
              </>
            )}

            {oneDriveFeatureEnabled && (
              <>
                <br />
                <OneDriveIntegrationInfo
                  connection={oneDriveConnected}
                  name="OneDrive"
                  onConnect={async () => {
                    await refetch();
                    history.replace('/settings/integrations/OneDrive');
                  }}
                />
              </>
            )}
          </>
        </TableSimple>
      </div>
    </>
  );
}
