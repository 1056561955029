import { useQuery, gql, useMutation } from '@apollo/client';
import { CATEGORIES } from '../Consts/promptTemplates';
import { useContext, useMemo } from 'react';
import { ID } from '../Models';
import { ProjectChecklistContext } from '../Context/ProjectChecklistContext';

type TCreatedByUser = {
  id: ID;
  name: string;
  email: string;
  avatar: string;
  picture: string;
};

export type TPrompt = {
  id: string;
  category: string | null;
  defaultIcon: string;
  description: string;
  icon: string;
  isPublic: boolean;
  organizationId: string;
  slug: string;
  title: string;
  type: string;
  detailsUrl?: string;
  favoriteId?: string;
  createdAt: number;
  userByCreatedBy?: TCreatedByUser;
  mainPrompt: string;
  isEnabled?: boolean;
  defaultLang?: string;
  outputLength: string;
  updatedAt?: string;
  preview?: string;
  isPopular?: boolean;
  isSmart?: boolean;
};

export type TCreatePromptObject = {
  description: string;
  isEnabled: boolean;
  mainPrompt: string;
  title: string;
  type: string;
  outputLength: string;
};

export type TUpdatePromptObject = {
  description?: string;
  mainPrompt?: string;
  title?: string;
  type?: string;
  isEnabled?: boolean;
  defaultLang?: string;
  outputLength?: string;
  defaultIcon?: string;
};

const ORG_PROMPTS = gql`
  query orgPrompts($promptType: String, $filter: OrgPromptFilter) {
    orgPrompts(condition: { type: $promptType }, filter: $filter) {
      category
      defaultIcon
      description
      icon
      id
      isPublic
      organizationId
      slug
      title
      type
      favoriteId
      detailsUrl
      createdAt
      isEnabled
      isPopular
      isSmart
    }
  }
`;

const PROMPT_BY_ID = gql`
  query fetchPromptWithDetails($id: BigInt!) {
    promptWithDetails(id: $id) {
      createdAt
      id
      description
      category
      isEnabled
      isPublic
      isSmart
      mainPrompt
      title
      type
      defaultLang
      outputLength
      updatedAt
      defaultIcon
      icon
      preview
      userByCreatedBy {
        avatar
        email
        id
        name
        picture
      }
    }
  }
`;

const CREATE_PROMPT = gql`
  mutation createPromptMutation($prompt: PromptWithDetailsInput) {
    createPrompt(input: { prompt: $prompt }) {
      success
      prompt {
        id
        createdAt
        description
        isEnabled
        mainPrompt
        title
        type
      }
    }
  }
`;

const UPDATE_PROMPT = gql`
  mutation updatePromptMutation($id: BigInt!, $patch: PromptWithDetailsInput) {
    updatePrompt(input: { id: $id, patch: $patch }) {
      prompt {
        createdAt
        description
        isEnabled
        id
        mainPrompt
        title
        type
      }
      success
    }
  }
`;

const DELETE_PROMPT = gql`
  mutation deletePromptMutation($id: BigInt!) {
    deletePrompt(input: { id: $id }) {
      success
      id
    }
  }
`;

const ADD_FAVORITE = gql`
  mutation addFavorite($promptId: BigInt!) {
    createFavoritePrompt(input: { favoritePrompt: { promptId: $promptId } }) {
      clientMutationId
    }
  }
`;

const DELETE_FAVORITE = gql`
  mutation deleteFavorite($id: BigInt!) {
    deleteFavoritePrompt(input: { id: $id }) {
      clientMutationId
    }
  }
`;

type TUsePromptsProps = {
  promptType?: string;
  getAll?: boolean;
};

export const usePrompts = (options?: TUsePromptsProps): [boolean, TPrompt[], () => void] => {
  const { getAll, promptType } = options || {};

  const filter = getAll
    ? { or: [{ isEnabled: { equalTo: true } }, { isEnabled: { equalTo: false } }] }
    : { isEnabled: { equalTo: true } };

  const { data, loading, refetch } = useQuery(ORG_PROMPTS, {
    variables: { promptType, filter },
    fetchPolicy: 'network-only',
  });

  const prompts = useMemo(
    () =>
      [...((data?.orgPrompts as TPrompt[]) || [])]
        .map((prompt) => ({ ...prompt, createdAt: new Date(prompt.createdAt).getTime() }))
        .sort((a, b) => {
          if (a.title && a.title?.toLowerCase()?.includes('discovery')) return -1;
          const categoryA = CATEGORIES.findIndex((category) => category.id === a.category);
          const categoryB = CATEGORIES.findIndex((category) => category.id === b.category);
          if (categoryA === categoryB) return 0;
          return categoryA > categoryB ? 1 : -1;
        }),
    [data?.orgPrompts]
  );

  return [loading, prompts, refetch];
};

export const useFetchPromptById = (id: string): [boolean, TPrompt, () => void] => {
  const { data, loading, refetch } = useQuery(PROMPT_BY_ID, {
    variables: { id },
  });

  return [loading, data?.promptWithDetails, refetch];
};

export const useCreatePrompt = (): [(prompt: TCreatePromptObject) => any] => {
  const [createMutation] = useMutation(CREATE_PROMPT);
  const { customTemplateAdded, markCustomTemplateAdded } = useContext(ProjectChecklistContext);

  return [
    async (prompt: TCreatePromptObject) => {
      const res = await createMutation({
        variables: {
          prompt,
        },
      });

      if (!customTemplateAdded) {
        markCustomTemplateAdded();
      }

      return res.data?.createPrompt?.prompt;
    },
  ];
};

export const useUpdatePrompt = (): [(template: TPrompt, patch: TUpdatePromptObject) => any] => {
  const [createMutation] = useMutation(UPDATE_PROMPT);

  return [
    async (template: TPrompt, patch: TUpdatePromptObject) => {
      const res = await createMutation({
        variables: {
          id: template.id,
          patch,
        },
        refetchQueries: [
          {
            query: ORG_PROMPTS,
            variables: {
              filter: { or: [{ isEnabled: { equalTo: true } }, { isEnabled: { equalTo: false } }] },
            },
          },
        ],
      });

      return res.data?.updatePrompt?.prompt;
    },
  ];
};

export const useDeletePrompt = (): [(id: string) => any] => {
  const [deleteMutation] = useMutation(DELETE_PROMPT);

  return [
    async (id: string) => {
      const res = await deleteMutation({
        variables: {
          id,
        },
        optimisticResponse: {
          deletePrompt: {
            id,
            success: true,
          },
        },
        update: (cache, { data: { deletePrompt } }) => {
          if (!deletePrompt.success) return;
          const data: any = cache.readQuery({
            query: ORG_PROMPTS,
            variables: {
              filter: { or: [{ isEnabled: { equalTo: true } }, { isEnabled: { equalTo: false } }] },
            },
          });

          cache.writeQuery({
            query: ORG_PROMPTS,
            variables: {
              filter: { or: [{ isEnabled: { equalTo: true } }, { isEnabled: { equalTo: false } }] },
            },
            data: {
              orgPrompts: data.orgPrompts.filter((x: any) => x.id !== deletePrompt.id),
            },
          });
        },
      });

      return res;
    },
  ];
};

export const useAddFavorite = (): [(promptId: string) => any] => {
  const [createMutation] = useMutation(ADD_FAVORITE);

  return [
    async (promptId: string) => {
      const res = await createMutation({
        variables: {
          promptId,
        },
      });
      return res;
    },
  ];
};

export const useDeleteFavorite = (): [(id: string) => any] => {
  const [deleteMutation] = useMutation(DELETE_FAVORITE);

  return [
    async (id: string) => {
      const res = await deleteMutation({
        variables: {
          id,
        },
      });

      return res;
    },
  ];
};
