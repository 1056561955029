import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Editor } from '@tiptap/react';
import {
  Button,
  Buttons,
  ButtonsContainer,
  Container,
  RestButtonsConainer,
  ToolbarButton,
  ToolbarDivider,
} from './styles';

import { Icon24 } from '../../Icons/Icon';
import EditorLinkModal from '../EditorLinkModal';
import EditorImageModal from '../EditorImageModal';
import PortalNew from '../PortalNew';

import { ArrowDownTrayIcon } from '@heroicons-v2/react/24/outline';
import { EvidenceContext } from '../../Context/EvidenceContext';
import EvidenceModal from '../EvidenceModal';
import { Insight } from '../../Models';
import DefaultButton from '../Button';
import NestedDropdown from '../NestedDropdown';
import { TNestedDropdownItem } from '../NestedDropdown/NestedDropdown';
import SearchAndReplacePanel from '../SearchAndReplacePanel';
import { EditorContext } from '../../Context/EditorContext';

export type DownloadData = {
  filename: string;
  content: string;
};

function download(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

interface EditorToolbarProps {
  editor: Editor | null;
  withToolbarMargin?: boolean;
  insight?: Insight;
  isInsightsPanel?: boolean;

  getDownloadData?(editor: Editor | null): DownloadData;
}

const EditorToolbar: React.FC<EditorToolbarProps> = ({
  editor,
  withToolbarMargin,
  insight,
  isInsightsPanel,
  getDownloadData,
}) => {
  const [modalWithLinkOpen, setModalWithLinkOpen] = useState({ isOpen: false, url: '', text: '' });
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const { showSearchAndReplace, setShowSearchAndReplace } = useContext(EditorContext);

  const { addEvidence } = useContext(EvidenceContext);

  const setLink = () => {
    if (!editor) return;
    const { from, to } = editor.state.selection;
    const text = editor.state.doc.textBetween(from, to, ' ');

    setModalWithLinkOpen({
      isOpen: true,
      url: editor.getAttributes('link').href || '',
      text: text,
    });
  };

  const setImage = () => {
    setIsImageModalOpen(true);
  };

  const handleAddImageComplete = (src: string) => {
    if (editor) {
      editor.chain().focus().setImage({ src }).run();
    }
    setIsImageModalOpen(false);
  };

  const handleLinkComplete = ({ url, text }: { url: string; text: string }) => {
    if (!editor) return;
    if (!url) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    } else {
      const { from, to } = editor.view.state.selection;
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
      editor.chain().focus().insertContentAt({ from, to }, text).run();
    }

    setModalWithLinkOpen({ isOpen: false, url: '', text: '' });
  };

  const formattingItems = useMemo((): TNestedDropdownItem[] => {
    return [
      {
        icon: <Icon24.Paragraph />,
        title: 'Paragraph',
        onClick: () => {
          if (editor?.isActive('heading', { level: 1 }))
            editor?.chain().focus().toggleHeading({ level: 1 }).run();
          if (editor?.isActive('heading', { level: 2 }))
            editor?.chain().focus().toggleHeading({ level: 2 }).run();
          if (editor?.isActive('heading', { level: 3 }))
            editor?.chain().focus().toggleHeading({ level: 3 }).run();
          if (editor?.isActive('orderedList')) editor?.chain().focus().toggleOrderedList().run();
          if (editor?.isActive('bulletList')) editor?.chain().focus().toggleBulletList().run();
        },
      },
      {
        icon: <Icon24.H1 />,
        title: 'Heading 1',
        onClick: () => editor?.chain().focus().toggleHeading({ level: 1 }).run(),
      },
      {
        icon: <Icon24.H2 />,
        title: 'Heading 2',
        onClick: () => editor?.chain().focus().toggleHeading({ level: 2 }).run(),
      },
      {
        icon: <Icon24.H3 />,
        title: 'Heading 3',
        onClick: () => editor?.chain().focus().toggleHeading({ level: 3 }).run(),
      },
      {
        icon: <Icon24.OrderedList />,
        title: 'Ordered List',
        onClick: () => editor?.chain().focus().toggleOrderedList().run(),
      },
      {
        icon: <Icon24.BulletList />,
        title: 'Bullet List',
        onClick: () => editor?.chain().focus().toggleBulletList().run(),
      },
    ];
  }, [editor]);

  const currentActiveFormatting = () => {
    if (editor?.isActive('heading', { level: 1 })) {
      return {
        icon: <Icon24.H1 />,
        title: 'Heading 1',
      };
    }

    if (editor?.isActive('heading', { level: 2 })) {
      return {
        icon: <Icon24.H2 />,
        title: 'Heading 2',
      };
    }

    if (editor?.isActive('heading', { level: 3 })) {
      return {
        icon: <Icon24.H3 />,
        title: 'Heading 3',
      };
    }

    if (editor?.isActive('orderedList')) {
      return {
        icon: <Icon24.OrderedList />,
        title: 'Ordered List',
      };
    }

    if (editor?.isActive('bulletList')) {
      return {
        icon: <Icon24.BulletList />,
        title: 'Bullet List',
      };
    }

    return {
      icon: <Icon24.Paragraph />,
      title: 'Paragraph',
    };
  };

  if (!editor) {
    return null;
  }

  return (
    <Container withToolbarMargin={withToolbarMargin}>
      <ButtonsContainer>
        <Buttons>
          <ToolbarButton>
            <NestedDropdown
              title={currentActiveFormatting().title}
              titleIcon={currentActiveFormatting().icon}
              items={formattingItems}
            />
          </ToolbarButton>

          <ToolbarDivider />

          <RestButtonsConainer>
            <Button
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              isActive={editor.isActive('bold')}
            >
              <Icon24.Bold />
            </Button>

            <Button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
              isActive={editor.isActive('italic')}
            >
              <Icon24.Italic />
            </Button>

            <Button onClick={setImage}>
              <Icon24.Image />
            </Button>

            <Button
              onClick={setLink}
              isActive={editor.isActive('link')}
              disabled={editor.view.state.selection.empty}
            >
              <Icon24.Link />
            </Button>
          </RestButtonsConainer>
        </Buttons>

        <Buttons>
          {insight && !isInsightsPanel && (
            <DefaultButton type="secondary" onClick={() => setIsEvidenceModalOpen(true)}>
              <Icon24.Plus />
              Add
            </DefaultButton>
          )}

          {getDownloadData && (
            <Button
              onClick={() => {
                const { filename, content } = getDownloadData(editor);
                download(`${filename}.txt`, content);
              }}
            >
              <ArrowDownTrayIcon width={24} />
            </Button>
          )}
        </Buttons>
      </ButtonsContainer>

      <EditorLinkModal
        url={modalWithLinkOpen.url}
        text={modalWithLinkOpen.text}
        isOpen={modalWithLinkOpen.isOpen}
        onComplete={handleLinkComplete}
        onClose={() => setModalWithLinkOpen({ isOpen: false, url: '', text: '' })}
      />

      <EditorImageModal
        isOpen={isImageModalOpen}
        onComplete={handleAddImageComplete}
        onClose={() => setIsImageModalOpen(false)}
      />

      {insight && !isInsightsPanel && (
        <PortalNew wrapperId="plusButton">
          <EvidenceModal
            dashboardId={insight.dashboardId}
            insightId={insight.id}
            isOpen={isEvidenceModalOpen}
            onClose={() => setIsEvidenceModalOpen(false)}
            onSubmit={(evidences) => {
              for (const note of evidences.notes || []) {
                const id = Math.floor(Math.random() * Date.now()).toString(16);
                const newNoteBlock = {
                  type: 'noteBlock',
                  attrs: { id, dashboardId: note.dashboardId },
                };

                const anchor = editor.state.selection.anchor;
                const { size: endPosition } = editor.view.state.doc.content;

                addEvidence({
                  dashboardId: insight.dashboardId,
                  parentInsightId: insight.id,
                  entityId: id,
                  noteId: note.id,
                });

                setTimeout(() => {
                  editor
                    .chain()
                    .focus()
                    .insertContentAt(anchor === 1 ? endPosition : anchor, newNoteBlock)
                    .run();
                  editor.chain().focus(anchor).run();
                }, 0);
              }

              for (const currentInsight of evidences.insights || []) {
                const id = Math.floor(Math.random() * Date.now()).toString(16);
                const newInsightBlock = {
                  type: 'insightBlock',
                  attrs: {
                    id,
                    dashboardId: insight.dashboardId,
                  },
                };

                const anchor = editor.state.selection.anchor;

                addEvidence({
                  dashboardId: insight.dashboardId,
                  entityId: id,
                  parentInsightId: insight.id,
                  insightId: currentInsight.id,
                });

                setTimeout(
                  () =>
                    editor
                      .chain()
                      .focus()
                      .insertContentAt({ from: anchor, to: anchor + 1 }, newInsightBlock)
                      .run(),
                  0
                );
              }

              setIsEvidenceModalOpen(false);
            }}
          />
        </PortalNew>
      )}

      {showSearchAndReplace && (
        <SearchAndReplacePanel editor={editor} onClose={() => setShowSearchAndReplace(false)} />
      )}
    </Container>
  );
};

export default EditorToolbar;
