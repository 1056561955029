import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { defaultProjectIcons } from '../Consts/projectIcons';
import InsightsIcon2 from '../Icons/InsightsIcon2';
import AnalysisIcon from '../Icons/AnalysisIcon';
import { Menu } from '@headlessui/react';
import { DocumentTextIcon, CollectionIcon, HomeIcon, ChartPieIcon } from '@heroicons/react/outline';
import { DropDownMenu } from './DropDownMenu';
import { useFetchBoard } from '../Hooks/useBoards';
import { Icon20 } from '../Icons/Icon';
import styled from 'styled-components';

type Props = {
  dashboardId: string;
};

export default function ProjectMenu({ dashboardId }: Props): JSX.Element {
  const [loadingDashboard, dashboard] = useFetchBoard(dashboardId);

  const icon =
    defaultProjectIcons.find((icon) => icon.name === dashboard?.cover) || defaultProjectIcons[0];

  return (
    <DropDownMenu
      alignLeft={true}
      button={
        <Menu.Button className="flex flex-row items-center flex-shrink font-medium">
          <img
            className="m-3 rounded-md"
            src={icon?.url}
            alt="Navigation menu"
            style={{
              width: 24,
              height: 24,
              objectFit: 'cover',
            }}
          />
          <div className="text-ellipsis whitespace-nowrap overflow-hidden text-sm max-w-[176px]">
            {dashboard?.name}
          </div>
        </Menu.Button>
      }
    >
      <Menu.Item>
        <Link
          to={`/projects/${dashboardId}/info`}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-purple-light font-medium"
        >
          <DocumentTextIcon className="mr-3 h-5 w-5" />
          Info
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`/projects/${dashboardId}/data`}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-purple-light font-medium"
        >
          <CollectionIcon className="mr-3 h-5 w-5" />
          Data
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`/projects/${dashboardId}/tags`}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-purple-light font-medium"
        >
          <Icon20.Tags className="mr-3 h-5 w-5" />
          Tags
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`/projects/${dashboardId}/notes`}
          className="group flex items-center px-4 py-2 text-sm  font-medium bg-primary-purple-hover"
        >
          <span className={'inline-flex pr-[14px] pl-[2px]'}>
            <AnalysisIcon />
          </span>
          Analysis
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`/projects/${dashboardId}/analysis/charts`}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-purple-light font-medium"
        >
          <span className={'inline-flex pr-[10px]'}>
          <ChartPieIcon
            width={22}
            viewBox="0 0 26 26"
          />
          </span>
          Charts
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`/projects/${dashboardId}/insights`}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-purple-light font-medium"
        >
          {/* <StarIcon className="mr-3 h-5 w-5" /> */}
          <InsightsIcon2 className="mr-3 h-5 w-5" />
          Insights
        </Link>
      </Menu.Item>
      <hr />
      <Menu.Item>
        <Link
          to={`/`}
          className="group flex items-center px-4 py-2 text-sm hover:bg-secondary-purple-light font-medium"
        >
          <HomeIcon className="mr-3 h-5 w-5" />
          Back to workspace
        </Link>
      </Menu.Item>
    </DropDownMenu>
  );
}

const SecondaryLabel = styled.span`
  margin-left: 5px;
  display: flex;
  padding: 0px 3px;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  background: rgba(56, 33, 82, 0.20);
  color: #382152;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;
