import { Editor } from '@tiptap/react';
import { TInnerHighlight } from '../../../../Context/HighlightsContext';
import { TEditorEvidence } from '../../../../Context/EvidenceContext';
import { difference } from 'lodash-es';
import { ID } from '../../../../Models';
import { THighlight } from '../../../../Models/Highlight';

type SpeakerBlock = {
  text: string;
  startTime: number;
  endTime: number;
};

type THighlightContextRefs = {
  setHighlightsListRef?: React.MutableRefObject<(highlights: TInnerHighlight[]) => void>;
  setEvidencesListRef?: React.MutableRefObject<(evidences: TEditorEvidence[]) => void>;
  rawHighlightsRef?: React.MutableRefObject<THighlight[]>;
  removeHighlightFromDBRef?: React.MutableRefObject<(ids: ID[]) => void>;
};

export const generateEditorEntities = (
  editor: Editor,
  {
    setHighlightsListRef,
    setEvidencesListRef,
    rawHighlightsRef,
    removeHighlightFromDBRef,
  }: THighlightContextRefs
): void => {
  if (!setHighlightsListRef && !setEvidencesListRef) return;

  const newHighlights: TInnerHighlight[] = [];
  const evidences: TEditorEvidence[] = [];
  let previousNodeId: string | null = null;

  let speakerBlock: SpeakerBlock | null = null;
  editor.state.doc.content.nodesBetween(0, editor.state.doc.content.size, (node, pos) => {
    // Evidences
    if (setEvidencesListRef) {
      if (
        node.type.name === 'noteBlock' ||
        node.type.name === 'insightBlock' ||
        node.type.name === 'themeBlock' ||
        node.type.name === 'tagBlock'
      ) {
        evidences.push({
          id: node.attrs.id,
          dashboardId: node.attrs.dashboardId,
        });
      }
    }

    // Highlights
    if (!setHighlightsListRef) return;
    if (node.type.name === 'speaker') {
      speakerBlock = {
        text: node.textContent || '',
        startTime: node.attrs.startTime,
        endTime: node.attrs.endTime,
      };
    }
    if (node.type.name === 'highlightTag') {
      if (previousNodeId !== node.attrs.id && node.attrs.serialNumber !== 0) {
        const newNode = {
          ...node.toJSON(),
          attrs: { ...node.attrs, newTag: false, serialNumber: 0 },
        };

        setTimeout(
          () =>
            editor
              .chain()
              .focus()
              .insertContentAt(
                {
                  from: pos,
                  to: pos + node.nodeSize,
                },
                newNode
              )
              .run(),
          0
        );
      }
      previousNodeId = node.attrs.id;
      const highlightIndex = newHighlights.findIndex((item) => item.entityId === node.attrs.id);

      const highlightText = node.firstChild?.text || '';

      if (highlightIndex !== -1) {
        newHighlights[highlightIndex].texts = newHighlights[highlightIndex].texts.concat(
          node.firstChild?.text || ''
        );
        return;
      }

      let startTime = null;
      let endTime = null;
      if (highlightText.length && speakerBlock) {
        const wholeText = speakerBlock.text;
        const textSplitedByHighlight = wholeText.split(highlightText);
        const countOfWordsBeforeHighlight = textSplitedByHighlight[0].split(' ').length - 1;
        const allWords = wholeText.split(' ');
        const percantagesOfWords: number[] = allWords.map(
          (word) => +Number(word.length / (wholeText.length - allWords.length - 1)).toFixed(10)
        );

        const percantageBeforeHighlight = countOfWordsBeforeHighlight
          ? percantagesOfWords.slice(0, countOfWordsBeforeHighlight - 1).reduce((a, b) => a + b, 0)
          : 0;

        const percentageAfterHighlight = percantagesOfWords
          .slice(countOfWordsBeforeHighlight + highlightText.split(' ').length)
          .reduce((a, b) => a + b, 0);

        startTime =
          +speakerBlock.startTime +
          percantageBeforeHighlight * (speakerBlock.endTime - speakerBlock.startTime);

        endTime =
          +speakerBlock.endTime -
          percentageAfterHighlight * (speakerBlock.endTime - speakerBlock.startTime);
      }

      newHighlights.push({
        entityId: node.attrs.id,
        texts: [node.firstChild?.text || ''],
        tags: [],
        startTime,
        endTime,
        addedToAnalysis: false,
        speakerId: node.attrs.speakerId,
        createdByAi: node.attrs.createdByAi,
      });
    }
  });

  if (rawHighlightsRef?.current && rawHighlightsRef?.current.length !== 0) {
    const highlightsToDelete = difference(
      rawHighlightsRef.current.map((highlight) => highlight.entityId),
      newHighlights.map((highlight) => highlight.entityId)
    );

    removeHighlightFromDBRef?.current(
      rawHighlightsRef.current
        .filter((highlight) => highlightsToDelete.includes(highlight.entityId))
        .map((highlight) => highlight.id)
    );
  }

  setHighlightsListRef && setHighlightsListRef.current(newHighlights);
  setEvidencesListRef && setEvidencesListRef.current(evidences);
};
