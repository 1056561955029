import React, { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import { FETCH_NOTES, FETCH_PARTICIPANTS, FETCH_TAGS, FETCH_THEMES } from '../GraphQL/queries';
import { parse } from 'csv-parse/browser/esm/sync';
import useNotes from '../Hooks/useNotes';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/browser';
import { uniq } from 'lodash-es';
import { useCreateParticipant } from '../Hooks/useParticipants';
import { STICKY_WIDTH } from '../Models';
import { useCreateTag } from '../Hooks/useTags';
import useThemes from '../Hooks/useThemes';
import { calcStartPosition, calcThemeSizeFromStickyCount } from '../Utils/canvas';
import { useImportMiroBoard, useUndoImportMiroBoard } from '../Hooks/useMiro';
import { MIRO_REDIRECT_URI } from '../Pages/Integrations';
import { useFetchIntegrations } from '../Hooks/useFetchIntegrations';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../Components/Button';
import { Icon24 } from '../Icons/Icon';

declare const miroBoardsPicker: any;
const MIRO_CLIENT_ID = (import.meta.env.VITE_MIRO_CLIENT_ID || '3458764572617899541') as string;

type ImportFromCSVRecord = {
  content?: string;
  note?: string;
  text?: string;
  tags?: string;
  date?: string;
  participant?: string;
  theme?: string;
};

const CloseButton = ({ closeToast }: { closeToast: any }) => (
  <div className="p-2.5" onClick={closeToast}>
    <Icon24.Close />
  </div>
);

export const useMiroImport = (dashboardId: string): [() => void] => {
  const { data: enabledIntegrations } = useFetchIntegrations();
  const { user } = useAuth0();
  const [importFromMiro] = useImportMiroBoard();
  const [undoMiroImport] = useUndoImportMiroBoard();

  const handleImportFromMiro = async () => {
    const miroConnected = enabledIntegrations?.integrations?.find(
      (x) => x.type === 'miro' && x.createdBy === user?.['https://notably.ai/claims/user_id']
    );

    if (!miroConnected) {
      const url = `https://miro.com/oauth/authorize?response_type=code&client_id=${MIRO_CLIENT_ID}&redirect_uri=${MIRO_REDIRECT_URI}`;
      window.location.href = url;
    }
    miroBoardsPicker?.open({
      clientId: MIRO_CLIENT_ID,
      action: 'select',
      success: async function (result: any) {
        const boardId = result?.id;
        if (!boardId) return;

        const toastId = toast.loading('Importing Miro board...');

        toast.update(toastId, {
          render: (
            <div className="ml-2">
              <div className="h-5 text-primary-purple text-base font-medium leading-tight">
                Importing Miro board...
              </div>
              <div className="text-primary-purple-light-40 text-sm font-medium leading-snug tracking-tight">
                This might take a few minutes based on the board size and your internet speed.
              </div>
            </div>
          ),
        });
        try {
          const res = await importFromMiro(dashboardId, {
            id: boardId,
            name: result.name,
            description: result.description,
            viewLink: result.viewLink,
          });

          toast.update(toastId, {
            render: (
              <div
                className="flex items-center justify-between"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="h-5 text-primary-purple text-base font-medium leading-tight">
                  Imported Miro board
                </div>
                {res?.isNew && (
                  <div>
                    <Button
                      type="secondary"
                      onClick={() => {
                        undoMiroImport(res?.internalId, dashboardId);
                        toast.dismiss(toastId);
                      }}
                    >
                      Undo
                    </Button>
                  </div>
                )}
              </div>
            ),
            type: 'success',
            isLoading: false,
            closeButton: CloseButton,
          });
        } catch (error) {
          toast.update(toastId, {
            render: (
              <div className="h-5 text-primary-purple text-base font-medium leading-tight">
                Unable to import board from Miro
              </div>
            ),
            type: 'error',
            isLoading: false,
            autoClose: 10000,
          });
        }
      },
    });
  };

  return [handleImportFromMiro];
};

export const useCSVImport = (dashboardId: string): [() => void] => {
  const { createNote } = useNotes(dashboardId);
  const { createTheme } = useThemes(dashboardId);
  const [createParticipant] = useCreateParticipant();
  const createTag = useCreateTag();
  const client = useApolloClient();

  const handleImportFromCsv = useCallback(async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      if (!target.files || !target.files.length) return;

      const file = target.files[0];

      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');

      reader.onload = async (readerEvent) => {
        let content = readerEvent?.target?.result as string;
        if (!content) return;
        const toastId = toast.loading('Importing notes...');

        try {
          let additionalRecord = null;
          if (isMiroFormat(content)) {
            content = convertFromMiroFormat(content);
          }
          const records: any[] = parse(content, {
            columns: (header) => {
              const columns: string[] = header.map((column: string) => column.toLowerCase());
              const hasHeader = ['note', 'content', 'text'].some((r) => columns.includes(r));
              if (!hasHeader) {
                additionalRecord = { note: columns[0] };
                return ['note', ...columns];
              }
              return columns;
            },
            skip_empty_lines: true,
            relax_column_count: true,
          });

          // handle issue with columns option removing header row
          if (additionalRecord) records.push(additionalRecord);

          const newNotesData: any[] = [];

          const participantNames = uniq(
            records
              .filter((record: ImportFromCSVRecord) => getNoteText(record) && record.participant)
              .map((record: ImportFromCSVRecord) => {
                return record.participant;
              })
          );

          let participants: { id: string; name: string }[] = [];

          if (participantNames?.length > 0) {
            const {
              data: { participants: existingParticipants = [] },
            } = await client.query({
              query: FETCH_PARTICIPANTS,
              fetchPolicy: 'network-only',
            });

            const newParticipantNames = participantNames.filter(
              (name) =>
                !existingParticipants.some(
                  (participant: { name: string }) =>
                    participant.name?.toLowerCase() === name?.toLowerCase()
                )
            );

            const newParticipants = await Promise.all(
              newParticipantNames.map((name) => createParticipant({ name }))
            );

            participants = [...existingParticipants, ...newParticipants];
          }

          const themeNames: string[] = uniq(
            records
              .filter((record: ImportFromCSVRecord) => getNoteText(record) && record.theme)
              .map((record: ImportFromCSVRecord) => {
                return record.theme || '';
              })
          );

          let existingThemes: any[] = [];

          if (themeNames.length > 0) {
            existingThemes =
              (
                await client.query({
                  query: FETCH_THEMES,
                  variables: {
                    dashboardId,
                  },
                  fetchPolicy: 'network-only',
                })
              )?.data?.themes || [];
          }

          const tagNames: string[] = uniq(
            records
              .filter((record: ImportFromCSVRecord) => getNoteText(record) && record.tags)
              .map((record: ImportFromCSVRecord) => {
                return record?.tags?.split(',') || [];
              })
              .flat()
          );

          let existingTags: any[] = [];

          if (tagNames.length > 0) {
            existingTags =
              (
                await client.query({
                  query: FETCH_TAGS,
                  variables: {
                    filter: {
                      or: [
                        { isGlobal: { equalTo: true } },
                        { dashboardId: { equalTo: dashboardId } },
                      ],
                    },
                    withDetails: false,
                  },
                  fetchPolicy: 'network-only',
                })
              )?.data?.tags || [];
          }

          const newTagNames = tagNames.filter(
            (name) =>
              !existingTags.some(
                (tag: { name: string }) => tag.name?.toLowerCase() === name?.toLowerCase()
              )
          );

          const newTags = await Promise.all(
            newTagNames.map((name) => createTag({ name, dashboardId }))
          );

          const tags = [...existingTags, ...newTags];

          const {
            data: { notes: existingNotes },
          } = await client.query({
            query: FETCH_NOTES,
            variables: {
              condition: {
                dashboardId,
              },
            },
          });

          const startPosition = calcStartPosition(existingNotes, existingThemes);

          records.forEach((record: ImportFromCSVRecord) => {
            const recordText = getNoteText(record);
            if (recordText) {
              const allTags = record.tags
                ?.split(',')
                ?.filter((x) => x)
                .map((tagName) => ({
                  tagId: tags?.find((tag) => tag.name?.toLowerCase() === tagName?.toLowerCase())
                    ?.id,
                }));

              const noteData: {
                text: string;
                createdAt: string;
                tagsNotes: any;
                participantId?: string;
                themeId: any;
              } = {
                text: recordText,
                createdAt: record.date
                  ? new Date(record.date).toISOString()
                  : new Date().toISOString(),
                tagsNotes: {},
                themeId: null,
              };
              if (allTags) {
                noteData.tagsNotes = {
                  create: allTags,
                };
              }

              if (record.participant) {
                const participant = participants.find(
                  (participant) => participant.name === record.participant
                );
                noteData.participantId = participant?.id;
              }

              if (record.theme) {
                noteData.themeId = record.theme;
              }

              newNotesData.push(noteData);
            }
          });

          const newNotesWithPositions = newNotesData
            .sort((a, b) => {
              if (b.themeId && !a.themeId) {
                return -1;
              }
              if (a.themeId && !b.themeId) {
                return 1;
              }
              return 0;
            })
            .map((note, index) => {
              if (note.themeId) return note;
              return {
                ...note,
                x: startPosition.x + (index % 5) * (STICKY_WIDTH + 32),
                y: startPosition.y + Math.floor(index / 5) * (STICKY_WIDTH + 32),
              };
            });

          const themesStartPosition = calcStartPosition(
            [...existingNotes, ...newNotesWithPositions],
            existingThemes
          );

          const newThemeNames = themeNames.filter(
            (name) =>
              !existingThemes.some(
                (theme: { name: string }) => theme.name?.toLowerCase() === name?.toLowerCase()
              )
          );

          const newThemes = await Promise.all(
            newThemeNames.map((name, index) => {
              const themeData = {
                name: name,
                x: themesStartPosition.x,
                y: themesStartPosition.y,
                dashboardId,
              };

              const themeNotes = newNotesWithPositions.filter((note) => note.themeId === name);
              const [themeWidth, themeHeight, rowSize] = calcThemeSizeFromStickyCount(
                themeNotes.length
              );

              themesStartPosition.x += themeWidth + 64;

              return createTheme(dashboardId, themeData, []);
            })
          );

          const themes = [...existingThemes, ...newThemes];

          const notesToAdd = newNotesWithPositions.map((note) => {
            if (note.themeId) {
              const theme = themes.find((theme) => theme.name === note.themeId);

              return {
                ...note,
                themeId: theme?.id,
              };
            }
            return note;
          });

          await Promise.all(notesToAdd.map((noteData) => createNote(dashboardId, noteData)));

          toast.update(toastId, {
            render: 'Successfully imported notes from csv!',
            type: 'success',
            isLoading: false,
            autoClose: 1000,
          });
        } catch (error) {
          captureException(error);
          toast.update(toastId, {
            render: 'Unable to parse the file.',
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        }
      };
    };
    input.click();
  }, [client, createNote, createParticipant, createTag, createTheme, dashboardId]);

  return [handleImportFromCsv];
};

function convertFromMiroFormat(input: string): string {
  const blocks = input.split('\n\n');
  const header = 'content,link,tags,theme\n';
  let result = header;

  for (const block of blocks) {
    const lines = block.split('\n');
    let theme = '';
    let start = 0;

    // Check if the first line is a theme line
    if (!lines[0].includes(',')) {
      theme = lines[0].replace(/"/g, '');
      start = 1;
    }

    for (let i = start; i < lines.length; i++) {
      const line = lines[i];
      // Append the theme at the end of the line
      result += `${line},${theme}\n`;
    }
  }

  return result;
}

function isMiroFormat(s: string): boolean {
  // Regex for a line with block name
  const blockNameRegex = /^"[^",]*"$/;

  // Regex for a content line
  const contentLineRegex = /^"[^"]*",("https?:\/\/[^"]*"|""|),(("[^"]*"|"[^"]*,[^"]*"))*$/;

  // Split the string into blocks
  const blocks = s.split('\n\n');

  // Validate each block
  for (const block of blocks) {
    // Split the block into lines
    const lines = block.split('\n');

    // If the first line is not empty and does not match the block name regex,
    // then it must match the content line regex. Otherwise, the format is invalid.
    if (lines[0] !== '' && !blockNameRegex.test(lines[0]) && !contentLineRegex.test(lines[0])) {
      return false;
    }

    // Validate the rest of the lines in the block
    for (let i = 1; i < lines.length; i++) {
      if (!contentLineRegex.test(lines[i]) && lines[i]) {
        return false;
      }
    }
  }

  return true;
}

const getNoteText = (record: ImportFromCSVRecord): string | undefined => {
  return record.note || record.content || record.text;
};
