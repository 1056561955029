import React, { FC, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { PropmptTemplate } from '../../Consts/promptTemplates';
import { useOrganization } from '../../Hooks/useOrganization';
import { Icon24 } from '../../Icons/Icon';
import Icon68 from '../../Icons/Icon/Icon68';
import TaskDetails from '../TaskDetails';
import TaskModal from '../TasksModal';
import TasksTable from '../TasksTable';
import {
  // AddTaskButton,
  // AddTaskText,
  ArrowIcon,
  Container,
  Divider,
  Header,
  HeaderIcon,
  HeaderText,
  PostySubtitle,
  PostyTitle,
  RemoveTaskButton,
  SummaryTemplate,
  SummaryTemplateIcon,
  SummaryTemplateName,
  Task,
  TaskContainer,
  TaskIcon,
  TaskLeftSide,
  TaskName,
  TaskPrice,
} from './styles';

export interface ITask {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  creditsPrice?: number;
  withSettings?: boolean;
  optionsList?: string[];
}

interface TaskManagerProps {
  tasks: ITask[];
  currentTasks: ITask[];
  hasVideo?: boolean;
  currentTemplate: PropmptTemplate | null;
  currentLanguage?: OptionTypeBase;
  currentHighlightPrompt?: string;
  currentTagsEnabled?: boolean;
  onChangeHighlightPrompt: (highlightPrompt: string) => void;
  onTagsEnabledChange: (tagsEnabled: boolean) => void;
  onTasksChange: (tasks: ITask[]) => void;
  onTemplateChange: (template: PropmptTemplate | null) => void;
  onCurrentLanguageChange?: (language: OptionTypeBase) => void;
}

const trancribeTask: ITask = {
  id: 'transcribe',
  name: 'Transcribe',
  description:
    'Choose a language to transcribe your videos in, or let Posty auto-detect the language.',
  icon: <Icon24.TaskTranscription />,
};

const TaskManager: FC<TaskManagerProps> = ({
  tasks,
  currentTasks,
  currentTemplate,
  hasVideo,
  currentLanguage,
  currentHighlightPrompt,
  currentTagsEnabled,
  onCurrentLanguageChange,
  onTasksChange,
  onTemplateChange,
  onChangeHighlightPrompt,
  onTagsEnabledChange,
}) => {
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState<ITask | null>(null);
  const [, org] = useOrganization();
  const hasCredits = org.aiCredits > 0;

  const handleChooseTask = (task: ITask, template?: PropmptTemplate | null) => {
    const existingTask = currentTasks.find((t) => t.id === task.id);
    !existingTask && onTasksChange([...currentTasks, task]);
    if (template) {
      onTemplateChange(template);
    }
    setTaskModalOpen(false);
    setCurrentTask(null);
  };

  const handleRemoveTask = (task: ITask) => {
    onTasksChange(currentTasks.filter((t) => t.id !== task.id));
    setCurrentTask(null);
  };

  const handleClickTask = (task: ITask) => {
    setCurrentTask(task);
  };

  const currentCreditsSum = useMemo(
    () => currentTasks.reduce((acc, task) => acc + (task.creditsPrice || 0), 0),
    [currentTasks]
  );

  return (
    <Container>
      <Header>
        <HeaderIcon>
          <Icon68.Posty />
        </HeaderIcon>

        <HeaderText>
          <PostyTitle>Posty Tasks</PostyTitle>
          <PostySubtitle>Choose tasks to automatically run on your data</PostySubtitle>
        </HeaderText>
      </Header>
      <TaskContainer>
        {hasVideo && (
          <>
            <Task key="transcribe" onClick={() => handleClickTask(trancribeTask)}>
              <TaskLeftSide>
                <TaskIcon>{trancribeTask.icon}</TaskIcon>
                <TaskName>{trancribeTask.name}</TaskName>
              </TaskLeftSide>
            </Task>
            {!!currentTasks.length && (
              <ArrowIcon>
                <Icon24.TaskArrow />
              </ArrowIcon>
            )}
          </>
        )}

        {currentTasks.map((task, index: number) => (
          <>
            <Task key={task.id} onClick={() => handleClickTask(task)}>
              <TaskLeftSide>
                <TaskIcon>{task.icon}</TaskIcon>
                <TaskName>{task.name}</TaskName>
                {task.creditsPrice && <TaskPrice>{task.creditsPrice} credits</TaskPrice>}
              </TaskLeftSide>
              {task.id === 'summarize' && currentTemplate && (
                <SummaryTemplate>
                  <SummaryTemplateIcon src={currentTemplate.icon} />
                  <SummaryTemplateName>{currentTemplate.title}</SummaryTemplateName>
                </SummaryTemplate>
              )}

              <RemoveTaskButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveTask(task);
                }}
              >
                <Icon24.Close />
              </RemoveTaskButton>
            </Task>
            {index < currentTasks.length - 1 && (
              <ArrowIcon>
                <Icon24.TaskArrow />
              </ArrowIcon>
            )}
          </>
        ))}
      </TaskContainer>

      {/* <AddTaskButton onClick={() => setTaskModalOpen(true)}>
        <Icon24.Plus />
        <AddTaskText>Add Task</AddTaskText>
      </AddTaskButton> */}

      {/* <TasksContainer> */}
      <TasksTable
        tasks={tasks}
        currentTasks={currentTasks}
        onChooseTask={(task) =>
          task.id === 'summarize' ? setCurrentTask(task) : handleChooseTask(task)
        }
        hasNoCredits={!hasCredits || currentCreditsSum >= org.aiCredits}
      />
      {/* </TasksContainer> */}

      <TaskModal
        isOpen={taskModalOpen || currentTask?.id === 'summarize'}
        onClose={() => {
          setTaskModalOpen(false);
          setCurrentTask(null);
        }}
        onChooseTask={handleChooseTask}
        tasks={tasks}
        currentTasks={currentTasks}
        currentTask={currentTask}
      />

      <TaskDetails
        isOpen={!!currentTask && currentTask.id !== 'summarize'}
        currentTask={currentTask}
        onClose={() => setCurrentTask(null)}
        currentLanguage={currentLanguage}
        onCurrentLanguageChange={onCurrentLanguageChange}
        onRemoveTask={handleRemoveTask}
        onChangeHighlightPrompt={onChangeHighlightPrompt}
        onTagsEnabledChange={onTagsEnabledChange}
        currentHighlightPrompt={currentHighlightPrompt}
        currentTagsEnabled={currentTagsEnabled}
      />
    </Container>
  );
};

export default TaskManager;
